import React, { useRef } from "react";
import ProfileHeader from "../pages/Components";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import { useToken } from "../components/TokenContext";

export default function Addservice() {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  const [values, setValues] = useState({
    name: "",
    description: "",
    time: "",
  });
  const { token } = useToken();
  
  const handleformsubmit = (event) => {
    event.preventDefault();
    axios
      .post("https://carwashapis.gosmart.ae/create_record", {
        table_name: "carwash.service",
        data: values,
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        history.push("/allservices");
      })
      .catch((err) => console.log(res));
  };
  return (
    <div>
      <ProfileHeader />
      <div className="d-flex w-100 ">
        <div className="w-100 v-100 pt-3 pb-5 ">
        <div className="title">
          <h1>        <Link to="/allservices"><img src={backbtn} style={{ marginRight: "1rem" }} /></Link>
             Add Service  </h1>

        </div>           
        <form onSubmit={handleformsubmit}>
            <div className="add-btns d-flex justify-content-center align-items-center">
              {/* <Link to="/allservices">
    <button className="add-btn-back">Back</button>
  </Link> */}
            </div>

            <div className="mb-2">
              <label htmlFor="name" className="form-label">
                {" "}
                Service Name :
              </label>
              <input
                type="text"
                name="name"
                id="updateforminput"
                className="form-control"
                placeholder="Enter Name "
                onChange={(e) => setValues({ ...values, name: e.target.value })}
              />
            </div>

            <div className="mb-2">
              <label htmlFor="name" className="form-label">
                Description:
              </label>
              <input
                type="text"
                name="name"
                id="updateforminput"
                className="form-control"
                placeholder="Enter Description"
                onChange={(e) =>
                  setValues({ ...values, description: e.target.value })
                }
              />
            </div>

            <div className="mb-2">
              <label htmlFor="name" class="form-label">
                Time:
              </label>
              <input
                type="text"
                name="name"
                id="updateforminput"
                className="form-control"
                placeholder="Enter Time"
                onChange={(e) => setValues({ ...values, time: e.target.value })}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="name"class="form-label">
                image:
              </label>
              <input
                type="text"
                name="name"
                id="updateforminput"
                className="form-control"
                placeholder="Enter Image URL"
                onChange={(e) =>
                  setValues({ ...values, image: e.target.value })
                }
              />
            </div>
            <div className="pull-right">
              <button className="btn updatepagebtn mt-4">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
