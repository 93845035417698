import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Modal from "react-modal";
import "../styles/Model.css";
import ProfileHeader from "../pages/Components";
import edit from "/webapps/carwash_landing/src/assets/edit-text.png";
import deletee from "/webapps/carwash_landing/src/assets/Frame 1770.png";
import { useToken } from "../components/TokenContext";
import view from "/webapps/carwash_landing/src/assets/view-icon.png";

const Home = ({ image, handleImageChange }) => {
  const { token } = useToken();
  const [deleteId, setDeleteId] = useState(null);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const history = useHistory();
  const [selectedItems, setSelectedItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (token) {
      axios
        .get("https://carwashapis.gosmart.ae/get_all/carwash_user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("Token:", token); // Log the token
          console.log("Response Data:", res.data); // Log the full response
          setData(res.data.data);
        })
        .catch((err) => {
          console.log("Error fetching data:", err);
          if (err.response) {
            // Server responded with a status other than 200 range
            console.log(`Error: ${err.response.status} - ${err.response.data.message}`);
          } else if (err.request) {
            // No response was received from the server
            console.log('No response received:', err.request);
          } else {
            // Other errors
            console.log('Error:', err.message);
          }
        });
    }
  }, [token]);

  const openModal = (id) => {
    setDeleteId(id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://carwashapis.gosmart.ae/delete_record/carwash.user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(data.filter((user) => user.id !== id)); // Remove the deleted user from the data array
        setIsOpen(false); // Close the modal
      })
      .catch((err) => console.log(err));
  };

  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedItems.indexOf(id);
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    }
  };

  return (
    <>
      <ProfileHeader />
      <div className="title">
        <h1>Users</h1>
      </div>

      <div className="d-flex justify-content-end">
        <span className="adduserbtn">
          <Link to="/create">+ Add User </Link>
        </span>
      </div>
      <table className="table shadow">
        <thead>
          <tr className="shadow">
            <th scope="col">Name</th>
            <th scope="col">ID</th>
            <th scope="col">Phone</th>
            <th scope="col">Latitude</th>
            <th scope="col">Longitude</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              <td>{d.id}</td>
              <td>{d.name}</td>
              <td>{d.phone}</td>
              <td>{d.latitude}</td>
              <td>{d.longitude}</td>
              <td>
                <Link to={`/info/${d.id}`}>
                  <button className="btn btn-sm readbtn">
                    <img src={view} className="viewicon" alt="View" />
                  </button>
                </Link>
                <Link to={`/update/${d.id}`}>
                  <button className="btn btn-sm editbtn">
                    <img src={edit} className="editbackground" alt="Edit" />
                  </button>
                </Link>
                <button
                  className="btn btn-sm deletebtn"
                  onClick={() => openModal(d.id)}
                >
                  <img src={deletee} className="editbackground" alt="Delete" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="Modal shadow"
      >
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this record?</p>
        <div className="btns">
          <button
            className="Confirm"
            onClick={() => {
              handleDelete(deleteId);
              setIsOpen(false);
            }}
          >
            Confirm
          </button>
          <button className="cancel" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Home;
