import React, { useState, useEffect } from "react";
import ProfileHeader from "../pages/Components";
import axios from "axios";
import { useParams } from "react-router-dom";
import deletee from "/webapps/carwash_landing/src/assets/Frame 1770.png";
import Modal from "react-modal";
import edit from "/webapps/carwash_landing/src/assets/edit-text.png";
import save from "/webapps/carwash_landing/src/assets/pngwing.com (2).png";
import { useToken } from "../components/TokenContext";

export default function Admin() {
  const { token } = useToken();
  const [data, setData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({});
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    axios
      .get("https://carwashapis.gosmart.ae/get_all/carwash_admin",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setData(res.data.data))
      .catch((err) => console.log(res));
  }, []);

  const handleCheckboxChange = (id) => {
    const updatedData = data.map((item) =>
      item.id === id ? { ...item, is_archive: !item.is_archive } : item
    );
    setData(updatedData);
    const updatedItem = updatedData.find((item) => item.id === id);
    handleUpdate(id, { is_archive: updatedItem.is_archive });

  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditData(data[index]);
  };

  const handleSaveClick = (index) => {
    const originalData = data[index];
    const updatedFields = getUpdatedFields(originalData, editData);
    if (Object.keys(updatedFields).length > 0) {
      handleUpdate(editData.id, updatedFields);
    } else {
      setEditIndex(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getUpdatedFields = (original, updated) => {
    const updatedFields = {};
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        updatedFields[key] = updated[key];
      }
    }
    return updatedFields;
  };

  const handleUpdate = (id, values) => {
    axios
      .post("https://carwashapis.gosmart.ae/Update_record", {
        object_id: id,
        table_name: "carwash.admin",
        data: values,
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        },})
      .then((res) => {
        console.log(res);
        const updatedData = [...data];
        updatedData[editIndex] = { ...updatedData[editIndex], ...values };
        setData(updatedData);
        setEditIndex(null);
      })
      .catch((err) => console.log(err));
  };

  const openModal = (id) => {
    setDeleteId(id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://carwashapis.gosmart.ae/delete_record/carwash.admin/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(data.filter((user) => user.id !== id)); // Remove the deleted user from the data array
        setIsOpen(false); // Close the modal
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };
  

  return (
    <div>
      <ProfileHeader />
      <h1 className="title">Clients</h1>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Phone</th>
            <th scope="col">Email</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Center Name</th>
            <th scope="col">Is Archive</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              <td>{d.id}</td>
              <td>
                {editIndex === i ? (
                  <input
                    type="text"
                    name="phone"
                    className="updateinputs"
                    value={editData.phone}
                    onChange={handleChange}
                  />
                ) : (
                  d.phone
                )}
              </td>
              <td>
                {editIndex === i ? (
                  <input
                    type="text"
                    name="email"
                    className="updateinputs"
                    value={editData.email}
                    onChange={handleChange}
                  />
                ) : (
                  d.email
                )}
              </td>
              <td>
                {editIndex === i ? (
                  <input
                    type="text"
                    name="first_name"
                    className="updateinputs"
                    value={editData.first_name}
                    onChange={handleChange}
                  />
                ) : (
                  d.first_name
                )}
              </td>
              <td>
                {editIndex === i ? (
                  <input
                    type="text"
                    name="last_name"
                    className="updateinputs"
                    value={editData.last_name}
                    onChange={handleChange}
                  />
                ) : (
                  d.last_name
                )}
              </td>
              <td>
                {editIndex === i ? (
                  <input
                    type="text"
                    name="center_name"
                    className="updateinputs"
                    value={editData.center_name}
                    onChange={handleChange}
                  />
                ) : (
                  d.center_name
                )}
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={d.is_archive}
                  disabled={editIndex !== i}
                  onChange={() => handleCheckboxChange(d.id)}
                />
              </td>
              <td>
                <div class="button-container d-flex">
                {editIndex === i ? (
                  <button
                    className="btn btn-sm editbtn"
                    style={{backgroundColor:"#198754"}}
                    onClick={() => handleSaveClick(i)}
                  >
                  <img src={save} className="editbackground" />

                  </button>
                ) : (
                  <button className="btn btn-sm editbtn "
                  onClick={() => handleEditClick(i)}
                  >
                  <img src={edit} className="editbackground" />
                </button>
              
                )}

                  <button
                  className="btn btn-sm deletebtn"
                  onClick={() => openModal(d.id)}
                >
                  <img src={deletee} className="editbackground" />
                </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="Modal shadow"
      >
        <h2>Confirm Delete</h2>
        <p>Are you Sure you want to delete this record .</p>
        <div className="btns">
          <button
            className="Confirm"
            onClick={() => {
              handleDelete(deleteId);
              setIsOpen(false);
            }}
          >
            Confirm
          </button>
          <button class="cancel" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </Modal>


    </div>
  );
}
