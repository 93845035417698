import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

const PaymentPage = () => {
  const { sessionid } = useParams();
  const { productId } = useParams();

  const history = useHistory();
  const [apiId, setApiId] = useState(null);

  
   useEffect(() => {

     axios.get(`https://carwashapis.gosmart.ae/create_new_subscription/${sessionid}/${productId}`)
     .then((res) => {       
      console.log(res)

    })
      .catch((err) => console.log(err));

   const timer = setTimeout(() => {
        history.push({
          pathname: '/',
       state: { id: apiId } // Pass the ID to the landing page
      });
     }, 1); // Redirect after 3 seconds
      return () => clearTimeout(timer);
    }, []);


  return (
    <div>
      <h2>Payment Information</h2>
      <p>Session ID: {sessionid}</p>
      <p>Product ID: {productId}</p>
    </div>
  );
}

export default PaymentPage;
