import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import edit from "/webapps/carwash_landing/src/assets/edit-text.png";
import save from "/webapps/carwash_landing/src/assets/pngwing.com (2).png";
import { useToken } from "../components/TokenContext";

const Content3 = ({ content }) => {
  const { id } = useParams();
  const [available_service, setAvailableService] = useState([]);
  const [editIndex3, setEditIndex] = useState(null);
  const [editData3, setEditData] = useState({});
  const { token } = useToken();

  useEffect(() => {
    if (content === "AvailableService") {
      axios
        .get(`https://carwashapis.gosmart.ae/details_of/available_service_day/service_id/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setAvailableService(res.data.data);
        })
        .catch((err) => console.error("Error fetching available service data:", err));
    }
  }, [id, content]);

  const handleEditClick3 = (index) => {
    setEditIndex(index);
    setEditData(available_service[index]);
  };

  const handleSaveClick3 = (index) => {
    const originalData = available_service[index];
    const updatedFields = getUpdatedFields(originalData, editData3);
    if (Object.keys(updatedFields).length > 0) {
      handleUpdate3(editData3.id, updatedFields);
    } else {
      setEditIndex(null);
    }
  };

  const handleChange3 = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getUpdatedFields = (original, updated) => {
    const updatedFields = {};
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        updatedFields[key] = updated[key];
      }
    }
    return updatedFields;
  };

  const handleUpdate3 = (id, values) => {
    axios.post("https://carwashapis.gosmart.ae/Update_record", {
      object_id: id,
      table_name: "available.service.day",
      data: values,
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res);
        const updatedData = [...available_service];
        updatedData[editIndex3] = { ...updatedData[editIndex3], ...values };
        setAvailableService(updatedData);
        setEditIndex(null);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div id="content3" style={{ display: content === "AvailableService" ? "block" : "none" }}>
      <div className="mt-5">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Day</th>
              <th>Center Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {available_service.map((d, i) => (
              <tr key={i}>
                <td>{d.id}</td>
                <td>
                  {editIndex3 === i ? (
                    <select
                      name="day"
                      className="updateinputs"
                      value={editData3.day || ""}
                      onChange={handleChange3}
                    >
                      <option value="">Select a day</option>
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                      <option value="Sunday">Sunday</option>
                    </select>
                  ) : (
                    d.day
                  )}
                </td>
                <td>

                  {d.provider_name}

                </td>
                <td>
                  {editIndex3 === i ? (
                    <button className="btn btn-sm editbtn" onClick={() => handleSaveClick3(i)}>
                    <img src={save} className="editbackground" />

                    </button>
                  ) : (
                    <button className="btn btn-sm editbtn" onClick={() => handleEditClick3(i)}>
                      <img src={edit} className="editbackground" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Content3;
