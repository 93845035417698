import React, { useEffect, useState } from "react";
import ProfileHeader from "../pages/Components";
import axios from "axios";
import "../styles/Model.css";
import { Link, useParams } from "react-router-dom";
import Modal from 'react-modal';
import edit from "/webapps/carwash_landing/src/assets/edit-text.png";
import deletee from "/webapps/carwash_landing/src/assets/Frame 1770.png";
import { useToken } from "../components/TokenContext";

export default function Operations() {
  const { token } = useToken();
  const {id} = useParams ();
  const [Operationsdata, setOperationdata] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    axios
      .get("https://carwashapis.gosmart.ae/get_all/carwash_operation",{
        headers: {
          Authorization: `Bearer ${token}`,
        },})
      .then((res) => setOperationdata(res.data.data))
      .catch((err) => console.log(res));
  }, []);
  const openModal = (id) => {
   setDeleteId(id);
   setIsOpen(true);
 };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDelete = (id) => {
   axios.delete("https://carwashapis.gosmart.ae/delete_record/carwash.operation/"+id ,{
    headers: {
      Authorization: `Bearer ${token}`,
    },})
     .then(res => {
      setOperationdata((prevData) => prevData.filter((operation) => operation.id !== id));
      setIsOpen(false); // Close the modal
     })
     .catch((err) => console.log(err));
     window.location.reload();

 };


  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedItems.indexOf(id);
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    }
  };
  const filteredData =  Operationsdata.filter((d) => {
    const bookingIdIncludes = String(d.booking_id).toLowerCase().includes(searchTerm.toLowerCase());
    const statusIncludes = d.status && d.status.toLowerCase().includes(searchTerm.toLowerCase());
    return bookingIdIncludes || statusIncludes;
  });
  return (
    <div>
      <ProfileHeader />
      <h1 className="title">Operations</h1>


    <form class="d-flex pb-3 " style={{width:"17%",float:"right"}}>
      <input class="form-control me-2" type="search" placeholder="Search by Status / Booking#" aria-label="Search"value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}/>
    </form>
    

      <table class="table">
        <thead>
          <tr>
            <th scope="col">booking ID</th>
            <th scope="col">Provider Name</th>
            <th scope="col">date</th>
            <th scope="col">is Arrive</th>
            <th scope="col">Start</th>
            <th scope="col">End</th>
            <th scope="col">status</th>
            <th scope="col">is Exit</th>
            <th scope="col">action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((d, i) => (
            <tr key={i}>
              <td>{d.booking_id}</td>
              <td>{d.provider_name}</td>
              <td>{d.date}</td>

              <td>
                <input
                  type="checkbox"
                  checked={d.is_arrive}
                  onChange={() => handleCheckboxChange(d.id)}
                  disabled={true}
                />
              </td>
              <td>{d.start === "Failed" ? "" : d.start}</td>
              <td>{d.end === "Failed" ? "" : d.end}</td>
              <td>{d.status}</td>

              <td>
                <input
                  type="checkbox"
                  checked={d.is_exit}
                  onChange={() => handleCheckboxChange(d.id)}
                  disabled={true}
                />
              </td>
              <td>
                {/* <Link to={`/updateoperation/${d.id}`}>
                  <button className="btn btn-sm editbtn mr-2">
                    <img src={edit} className="editbackground" />
                  </button>
                </Link> */}
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => openModal(d.id)}
                >
                  <img src={deletee} className="editbackground" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="Modal shadow"
      >
        <h2>Confirm Delete</h2>
        <p>Are you Sure you want to delete this record .</p>
        <div className="btns">

        <button className="Confirm" onClick={() => { handleDelete(deleteId); setIsOpen(false); }}>Confirm</button>
        <button class="cancel" onClick={closeModal}>Cancel</button>

        </div>
      </Modal>

    </div>
  );
}
