import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import { useToken } from "../components/TokenContext";

export default function Addpackage() {
  const history = useHistory();
  const [values, setValues] = useState({
    package_name: "",
    featuers: [] ,
  });
  const { token } = useToken();

  const [currentFeature, setCurrentFeature] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
  }, [values.featuers]);


  const handleFormSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
  
    axios.post(
      "https://carwashapis.gosmart.ae/new_package",
      values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(res => {
      console.log(res);
      history.push('/packages'); // Navigate to another page after successful submission
    })
    .catch(err => {
      console.error(err);
      setIsSubmitting(false); // Re-enable the button if there is an error
    });
  };
  

  const handleFeatureChange = (e) => {
    setCurrentFeature(e.target.value);
  };

  const addFeature = (e) => {
    e.preventDefault();
    if (currentFeature.trim() !== '') {
      setValues(prevValues => ({
        ...prevValues,
        featuers: [...prevValues.featuers, currentFeature], // Push currentFeature into the array
      }));
      setCurrentFeature('');
    }
  };
  

  const removeFeature = (index) => {
    const filteredFeatures = values.featuers.filter((_, i) => i !== index);
    setValues(prevValues => ({
      ...prevValues,
      featuers: filteredFeatures,
    }));
  };
  

  return (
    <div className="d-flex w-100 vh-100">
      <div className="w-100 border pt-3 pb-5 rounded">
        <div className="title">
          <h1>
            <Link to="/packages"><img src={backbtn} style={{ marginRight: "1rem" }} alt="Back" /></Link>
            Add Package
          </h1>
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="row">
            <div className="mb-3 col-12">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                name="package_name"
                id="updateforminput"
                className="form-control"
                placeholder="Enter Name"
                onChange={(e) => setValues({ ...values, package_name: e.target.value })}
              />
            </div>
            <div className="col-12">
              <label htmlFor="name">Add Features:</label>
              <div className="d-flex mb-3">
                <input
                  type="text"
                  id="updateforminput"
                  className="form-control"
                  value={currentFeature}
                  onChange={handleFeatureChange}
                  placeholder="Enter a feature"
                />
                <button className="btn btn-primary" style={{ borderRadius: "8px" }} onClick={addFeature}>+</button>
              </div>
              <ul className="list-group">
  {values.featuers.map((feature, index) => (
    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
      {feature}
      <button className="btn btn-danger btn-sm" onClick={() => removeFeature(index)}>Remove</button>
    </li>
  ))}
</ul>
            </div>
          </div>
          <div className="pull-right">
            <button className="btn updatepagebtn mt-4" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Add'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
