import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Content2 from "./content2";
import Content3 from "./content3";
import Content4 from "./content4";
import { useToken } from "../components/TokenContext";

export default function Edittables() {
  const [activeTag, setActiveTag] = useState("tag1");
  const [content, setContent] = useState("ServiceProvider");
  const [ServicePro, setServicePro] = useState([]);
  const { id } = useParams();
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({});
  const [editedData, setEditedData] = useState({});
  const { token } = useToken();

  const changeContent = (id, tagname) => {
    switch (id) {
      case "Service-provider":
        setContent("ServiceProvider");
        break;
      case "Service-options":
        setContent("ServiceOptions");
        break;
      case "Available-service":
        setContent("AvailableService");
        break;
      case "Rating":
        setContent("Rating");
        break;
      default:
        setContent("");
        break;
    }
    setActiveTag(tagname);
  };


   useEffect(() => {
    if (content === "ServiceProvider") {
      axios
        .get(`https://carwashapis.gosmart.ae/details_of/service_provider/service_id/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setServicePro(res.data.data);
        })
        .catch((err) => console.error("Error fetching service provider data:", err));
    } 
  }, [id, content]);

  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditData(ServicePro[index]);
  };

  const handleSaveClick = (index) => {
    const originalData = ServicePro[index];
    const updatedFields = getUpdatedFields(originalData, editData);
    if (Object.keys(updatedFields).length > 0) {
      handleUpdate(editData.id, updatedFields);
    } else {
      setEditIndex(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getUpdatedFields = (original, updated) => {
    const updatedFields = {};
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        updatedFields[key] = updated[key];
      }
    }
    return updatedFields;
  };

  const handleUpdate = (id, values) => {
    axios.post("https://carwashapis.gosmart.ae/Update_record", {
      object_id: id,
      table_name: "service.provider",
      data: values,
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res);
      const updatedData = [...ServicePro];
      updatedData[editIndex] = { ...updatedData[editIndex], ...values };
      setServicePro(updatedData);
      setEditIndex(null);
    })
    .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="menu-list">
        <ul>
          <li
            onClick={() => changeContent("Service-provider", "tag1")}
            className={`List-item3 ${activeTag === "tag1" ? "active" : ""}`}
          >
            <button className="all">Centers</button>
          </li>
          <li
            onClick={() => changeContent("Service-options", "tag2")}
            className={`List-item3 ${activeTag === "tag2" ? "active" : ""}`}
          >
            <a>Service Options</a>
          </li>
          <li
            onClick={() => changeContent("Available-service", "tag3")}
            className={`List-item3 ${activeTag === "tag3" ? "active" : ""}`}
          >
            <a>Availability</a>
          </li>
          <li
            onClick={() => changeContent("Rating", "tag4")}
            className={`List-item3 ${activeTag === "tag4" ? "active" : ""}`}
          >
            <a>Rating</a>
          </li>
        </ul>
      </div>

      <div
        id="content1"
        style={{ display: content === "ServiceProvider" ? "block" : "none" }}
      >
        <div className="mt-5">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Center Name</th>
                <th>Rating</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {ServicePro.map((d, i) => (
                <tr key={i}>
                  <td>{d.id}</td>
                  <td>
                    {editIndex === i ? (
                      <input
                      className="updateinputs"
                        type="text"
                        name="provider_name"
                        value={editData.provider_name || ""}
                        onChange={handleChange}
                      />
                    ) : (
                      d.provider_name
                    )}
                  </td>
          
                  <td>
                    {editIndex === i ? (
                      <input
                        type="text"
                        name="rating"
                        className="updateinputs"
                        value={editData.rating || ""}
                        onChange={handleChange}
                      />
                    ) : (
                      d.rating
                    )}
                  </td>
                  <td>
                    {editIndex === i ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleSaveClick(i)}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        className="btn btn-secondary"
                        onClick={() => handleEditClick(i)}
                      >
                        Archive
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Content2
        handleChange={handleChange}
        content={content}
        activeTag={activeTag}
      />

<Content3
        handleChange={handleChange}
        content={content}
        activeTag={activeTag}
      />


<Content4
        handleChange={handleChange}
        content={content}
        activeTag={activeTag}
      />

      {/* <div
        id="content2"
        style={{ display: content === "ServiceOptions" ? "block" : "none" }}
      >
        <div className=" mt-5">
          <table className="table ">
            <thead>
              <tr>
                <th>ID</th>
                <th>service_id</th>
                <th>vehicle_type_id</th>
                <th>price</th>
                <th>provider_id</th>
                <th>option_id</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {options.map((d, i) => (
                <tr key={i}>
                  <td>{d.id}</td>
                  <td>
                  {editIndex === i ? (
                      <input
                        type="text"
                        value={editedData.service_id}
                        onChange={handleChange}
                      />
                    ) : (
                      d.service_id
                    )}
                  </td>
                
                  <td>
                  {editIndex === i ? (
                      <input
                        type="text"
                        value={editedData.price}
                        onChange={handleChange}
                      />
                    ) : (
                      d.price
                    )}
                  </td>
                 
                  <td>
                  {editIndex === i ? (
                      <button className="btn btn-primary" >
                        Save
                      </button>
                    ) : (
                      <button className="btn btn-secondary" >
                        Edit
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> */}
    </div>
  );
}
