import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import "../styles/Read.css";
import ProfileHeader from "../pages/Components";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import { useToken } from "../components/TokenContext";

export default function Readuser() {
  const { id } = useParams();
  const [values, setValues] = useState({});
  const [Cardata, Setcardata] = useState([]);
  const { token } = useToken();

  useEffect(() => {
    axios
      .get(`https://carwashapis.gosmart.ae/get_by_id/carwash_user/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setValues(res.data.data[0]))
      .catch((err) => console.log(res));

    axios
      .get(`https://carwashapis.gosmart.ae/get_by_User_id/carwash_car/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => Setcardata(res.data.data))
      .catch((err) => console.log(res));
  }, []);

  return (
    <>
      <ProfileHeader />

      <div className="d-flex w-100 vh-100 justify-content-center align-items-center ">
        <div className="w-100 h-100 pt-3 pb-5 rounded">
          <div className="title">
            <h1>        <Link to="/dashboard"><img src={backbtn} style={{ marginRight: "1rem" }} /></Link>
              Customer Info</h1>

          </div>          
             
          <div className="grey-container">
            <div className="row">
              <div class="col-3 ">
                <span class="holder">Name :</span>
                <span className="values"> {values.name}</span>
              </div>

              <div class="col-3">
                <span class="holder">ID :</span>

                <span className="values">{values.id}</span>
              </div>

              <div class="col-3 ">
                <span class="holder">Phone :</span>{" "}
                <span className="values">{values.phone}</span>
              </div>
              <div class="col-3 ">
                <span class="holder">Latitude :</span>{" "}
                <span className="values">{values.latitude}</span>
              </div>
              <div class="col-3 pt-5">
                {" "}
                <span class="holder">Longitude :</span>{" "}
                <span className="values">{values.longitude}</span>
              </div>
              <div class="col-3 pt-5">
                {" "}
                <span class="holder">Password :</span>{" "}
                <span className="values">{values.password}</span>
              </div>
              <div class="col-3 pt-5">
                {" "}
                <span class="holder">Confirm Password :</span>{" "}
                <span className="values">{values.confirm_password}</span>
              </div>
            </div>
          </div>

          <div className="car-header">Customer's Cars Details</div>

          <table class="table mt-4">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">model_year</th>
                <th scope="col">color</th>
                <th scope="col">km</th>
                <th scope="col">Vehicle Type</th>
                <th scope="col">car_number</th>
                <th scope="col">image</th>
                <th scope="col">brand_name</th>
                <th scope="col">is Active</th>
              </tr>
            </thead>
            <tbody>
              {Cardata.map((d, i) => (
                <tr key={i}>
                  <td>{d.id}</td>
                  <td>{d.model_year}</td>
                  <td>{d.color}</td>
                  <td>{d.km}</td>
                  <td>{d.vehicle_type_name}</td>
                  <td>{d.car_number}</td>
                  <td>
                    <img src={d.image} width="100w" />

                  </td>
                  <td>{d.brand_name}</td>
                  <td>
                    <input type="checkbox" checked={d.is_active} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
