import React from "react";
import ProfileHeader from "../pages/Components";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import edit from "/webapps/carwash_landing/src/assets/edit-text.png";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import { useToken } from "../components/TokenContext";


export default function ServiceArchive() {

    const { id } = useParams();
    const [data, setData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const { token } = useToken();

    useEffect(() => {
        axios
          .get("https://carwashapis.gosmart.ae/get_all_archive/carwash_service",{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
    
          .then((res) => setData(res.data.data))
          .catch((err) => console.log(res));
      }, []);
    
      const handleCheckboxChange = (id) => {
        const selectedIndex = selectedItems.indexOf(id);
        if (selectedIndex === -1) {
          setSelectedItems([...selectedItems, id]);
        } else {
          setSelectedItems(selectedItems.filter((item) => item !== id));
        }
      };
  return (
    <div>
      <ProfileHeader />
      <h1 className="updateuser">Service Archives</h1>
      <table class="table shadow">
        <thead>
          <tr className="shadow">
            <th scope="col">Service Name</th>
            <th scope="col">Time By Minutes</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              <td>{d.name}</td>
              <td>{d.time}</td>
             
          
               <td >
              <Link to={`/unarchiveService/${d.id}`}>
                  <button className="btn btn-sm editbtn mr-2">
                    <img src={edit} className="editbackground"/>
                  </button>
                </Link>
                 </td> 
            </tr>
          ))}
        </tbody>
      </table>
    </div>  )
}
