import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link ,useHistory, useParams } from "react-router-dom";
import "../styles/updateform.css"
import ProfileHeader from "../pages/Components";
import SupportBrands from "./supportbrands";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import { useToken } from "../components/TokenContext";

const useAutoResizeTextarea = (textareaRef, value) => {
  
  useEffect(() => {
    const handleResize = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [textareaRef, value]);
};
export default function Editprovider() {
    const history = useHistory();
    const {id} = useParams ();
    const [values, setValues] = useState({});
    const [activeTag, setActiveTag] = useState("tag1");
    const [content, setContent] = useState("ProviderRating");
    const [editIndex, setEditIndex] = useState(null);
    const [editData, setEditData] = useState({});
    const [ProviderRating, SetproviderRating] = useState([]);
    const { token } = useToken();

    useEffect(() => {
        if (content === "ProviderRating") {
          axios
            .get(`https://carwashapis.gosmart.ae/details_of/provider_rating/provider_id/${id}`,{
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
                SetproviderRating(res.data.data);
            })
            .catch((err) => console.error("Error fetching service provider data:", err));
        } 
      }, [id, content]);

    const changeContent = (id, tagname) => {
        switch (id) {
          case "Provider-Rating":
            setContent("ProviderRating");
            break;
          case "Support-Brands":
            setContent("SupportBrands");
            break;
          default:
            setContent("");
            break;
        }
        setActiveTag(tagname);
      };
 
     useEffect(() => {axios.get(`https://carwashapis.gosmart.ae/get_by_id/carwash_provider/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((res) => setValues(res.data.data[0]))
  .catch((err) => console.log(res));
     }, []);
 
     const handleUpdate = (event) => {
      event.preventDefault();
      axios.post("https://carwashapis.gosmart.ae/Update_record", {
      object_id:id,
      table_name:"carwash.provider",
      data:values
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    .then(res => {console.log(res);
      history.push('/packages');
   })
    .catch((err) => console.log(res));
    };

    const handleEditClick = (index) => {
        setEditIndex(index);
        setEditData(ProviderRating[index]);
      };
    
      const handleSaveClick = (index) => {
        const originalData = ProviderRating[index];
        const updatedFields = getUpdatedFields(originalData, editData);
        if (Object.keys(updatedFields).length > 0) {
          handleUpdate2(editData.id, updatedFields);
        } else {
          setEditIndex(null);
        }
      };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getUpdatedFields = (original, updated) => {
    const updatedFields = {};
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        updatedFields[key] = updated[key];
      }
    }
    return updatedFields;
  };
 

  const handleUpdate2 = (id, values) => {
    axios.post("https://carwashapis.gosmart.ae/Update_record", {
      object_id: id,
      table_name: "provider.rating",
      data: values,
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res);
      const updatedData = [...ProviderRating];
      updatedData[editIndex] = { ...updatedData[editIndex], ...values };
      SetproviderRating(updatedData);
      setEditIndex(null);
    })
    .catch((err) => console.log(err));
  };

  const textareaRef = useRef(null);
  useAutoResizeTextarea(textareaRef, values.description);

  return (
    <div className="d-flex w-100 vh-100 ">
      <div className="w-100 border pt-3 pb-5 rounded">
        <ProfileHeader />
        <div className="title">
          <h1>        <Link to="/providers"><img src={backbtn} style={{ marginRight: "1rem" }} /></Link>
             Update Center</h1>

        </div>         <form onSubmit={handleUpdate}>
          <div className="row">
          {/* <div className="mb-2 col-6">
            <label htmlFor="name">Provider ID:</label>
            <input
              type="text"
              name="id"
              id="updateforminput"
              className="form-control"
              placeholder="Provider ID"
              value={values.id || ""}
              onChange={(e) =>
                setValues({ ...values, id: e.target.value })
               }            />
          </div> */}
          <div className="mb-2 col-6">
            <label htmlFor="name">Provider Name :</label>
            <input
              type="text"
              name="name"
              id="updateforminput"
              className="form-control"
              placeholder="Provider Name"
              value={values.name}
              onChange={(e) =>
                setValues({ ...values, name: e.target.value })
               }
            />
          </div>

          <div className="mb-2 col-6">
            <label htmlFor="name">Email :</label>
            <input
              type="text"
              name="email"
              id="updateforminput"
              className="form-control"
              placeholder="Email"
              value={values.email}
              onChange={(e) =>
                setValues({ ...values, email: e.target.value })
               }
            />
          </div>

          <div className="mb-2 col-6">
            <label htmlFor="name">Phone :</label>
            <input
              type="text"
              name="phone"
              id="updateforminput"
              className="form-control"
              placeholder="Phone"
              value={values.phone}
              onChange={(e) =>
                setValues({ ...values, phone: e.target.value })
               }
            />
          </div>
          <div className="mb-2 col-6">
            <label htmlFor="name">Capacity :</label>
            <input
              type="text"
              name="capacity"
              id="updateforminput"
              className="form-control"
              placeholder="Capacity "
              value={values.capacity}
              onChange={(e) =>
                setValues({ ...values, capacity: e.target.value })
               }
            />
          </div>
    
          <div className="mb-2 col-6">
            <label htmlFor="name">Latitude :</label>
            <input
              type="text"
              name="latitude"
              id="updateforminput"
              className="form-control"
              placeholder="latitude "
              value={values.latitude}
              onChange={(e) =>
                setValues({ ...values, latitude: e.target.value })
               }
            />
          </div>
          <div className="mb-2 col-6">
            <label htmlFor="name">Longitude :</label>
            <input
              type="text"
              name="longitude"
              id="updateforminput"
              className="form-control"
              placeholder="longitude"
              value={values.longitude}
              onChange={(e) =>
                setValues({ ...values, longitude: e.target.value })
               }
            />
          </div> 
          {/* <div className="mb-2 col-6">
            <label htmlFor="name">Location :</label>
            <input
              type="text"
              name="location"
              id="updateforminput"
              className="form-control"
              placeholder="location"
              value={values.location}
              onChange={(e) =>
                setValues({ ...values, location: e.target.value })
               }
            />
          </div>  */}
          
          <div className="mb-2 col-6">
            <label htmlFor="name">Rating :</label>
            <input
              type="text"
              name="rating"
              id="updateforminput"
              className="form-control"
              placeholder="rating"
              value={values.rating}
              onChange={(e) =>
                setValues({ ...values, rating: e.target.value })
               }
            />
          </div> 
          {/* <div className="mb-2 col-6">
            <label htmlFor="name">Rate Sum :</label>
            <input
              type="text"
              name="rate_sum"
              id="updateforminput"
              className="form-control"
              placeholder="rate_sum"
              value={values.rate_sum}
              onChange={(e) =>
                setValues({ ...values, rate_sum: e.target.value })
               }
            />
          </div> */}
           {/* <div className="mb-2 col-6">
            <label htmlFor="name">Rate Count :</label>
            <input
              type="text"
              name="rate_count"
              id="updateforminput"
              className="form-control"
              placeholder="rate_count"
              value={values.rate_count}
              onChange={(e) =>
                setValues({ ...values, rate_count: e.target.value })
               }
            />
          </div> */}

          {/* <div className="mb-2 col-6">
            <label htmlFor="name">Image :</label>
            <input
              type="text"
              name="image"
              id="updateforminput"
              className="form-control"
              placeholder="image"
              value={values.image}
              onChange={(e) =>
                setValues({ ...values, image: e.target.value })
               }
            />
          </div> */}
          <div className="mb-2 col-6">
            <label htmlFor="name">Working From :</label>
            <input
              type="text"
              name="working_from"
              id="updateforminput"
              className="form-control"
              placeholder="working_from"
              value={values.working_from}
              onChange={(e) =>
                setValues({ ...values, working_from: e.target.value })
               }
            />
          </div>
          <div className="mb-2 col-6">
            <label htmlFor="name">Working To :</label>
            <input
              type="text"
              name="working_to"
              id="updateforminput"
              className="form-control"
              placeholder="working_to"
              value={values.working_to}
              onChange={(e) =>
                setValues({ ...values, working_to: e.target.value })
               }
            />
          </div>
          <div className="mb-2 col-6">
            <label htmlFor="name">Client Name :</label>
            <input
              type="text"
              name="admin_id"
              id="updateforminput"
              className="form-control"
              placeholder="client_name"
              value={values.client_name}
              onChange={(e) =>
                setValues({ ...values, client_name: e.target.value })
               }
            />
          </div>
          <div className="mb-2 col-12">
            <label htmlFor="name">Description:</label>
            <textarea
                ref={textareaRef}
                type="text"
                id="updateforminput"
                name="description"
                className="form-control3"
                placeholder="Package Features"
                value={values.description || ""}
                onChange={handleChange}
                style={{
                  width: "100%",
                  padding: "1rem",
                  overflow: "hidden", // Hides the scrollbar
                  resize: "none",
                  border:"0"
                }}
              />
          </div>
          </div>
          <div className="pull-right">
          <button style={{marginBottom:"1rem"}} className="btn updatepagebtn mt-4">Update</button></div>
          {/* <Link to="/" className="btn btn-primary ms-3">
            Back
          </Link> */}
        </form>

        {/* <div className="menu-list pt-5 w-100">
        <ul>
          <li
            onClick={() => changeContent("Provider-Rating", "tag1")}
            className={`List-item3 ${activeTag === "tag1" ? "active" : ""}`}
          >
            <button className="all">Provider Ratings</button>
          </li>
          <li
            onClick={() => changeContent("Support-Brands", "tag2")}
            className={`List-item3 ${activeTag === "tag2" ? "active" : ""}`}
          >
            <a>Support Brands</a>
          </li>
       
        </ul>
      </div>

      <div
        id="content1"
        style={{ display: content === "ProviderRating" ? "block" : "none" }}
      >
        <div className="mt-5 pb-5">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Provider ID</th>
                <th>User ID</th>
                <th>Last Rating</th>
                <th>User Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {ProviderRating.map((d, i) => (
                <tr key={i}>
                  <td>{d.id}</td>
                  <td>
                    {editIndex === i ? (
                      <input
                      className="updateinputs"
                        type="text"
                        name="provider_id"
                        value={editData.provider_id || ""}
                        onChange={handleChange}
                      />
                    ) : (
                      d.provider_id
                    )}
                  </td>
                  <td>
                    {editIndex === i ? (
                      <input
                        type="text"
                        name="user_id"
                        className="updateinputs"
                        value={editData.user_id || ""}
                        onChange={handleChange}
                      />
                    ) : (
                      d.user_id
                    )}
                  </td>
                  <td>
                    {editIndex === i ? (
                      <input
                        type="text"
                        name="last_rating"
                        value={editData.last_rating || ""}
                        onChange={handleChange}
                        className="updateinputs"
                      />
                    ) : (
                      d.last_rating
                    )}
                  </td>
                  <td>
                    {editIndex === i ? (
                      <input
                        type="text"
                        name="user_name"
                        value={editData.user_name || ""}
                        onChange={handleChange}
                        className="updateinputs"
                      />
                    ) : (
                      d.user_name
                    )}
                  </td>
                  <td>
                    {editIndex === i ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleSaveClick(i)}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        className="btn btn-secondary"
                        onClick={() => handleEditClick(i)}
                      >
                        Edit
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <SupportBrands
        handleChange={handleChange}
        content={content}
        activeTag={activeTag}
      /> */}

      </div>

    
    </div>
  );
}
