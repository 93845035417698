import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useToken } from "../components/TokenContext";

export default function Addsupport() {
  const history = useHistory();
  const { id } = useParams();
  const [data,Setdata]=useState([]);  
  const [values, setValues] = useState({
  });
  const { token } = useToken();

  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedBrandId, setSelectedBrandId] = useState('');

  const handleChange = (event) => {
    setSelectedBrandId(event.target.value);
  };



  useEffect(() => {
      axios
        .get(`https://carwashapis.gosmart.ae/available_brands_of_provider/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
            Setdata(res.data.data);
        })
        .catch((err) => console.error("Error fetching service options data:", err));
    
  }, [id]);


  const handleformsubmit = (event) => {
    event.preventDefault();
    console.log("Form Values:", values); // Debugging statement
    axios.post("https://carwashapis.gosmart.ae/create_record", {
      table_name: "support.brands",
      data: {

        "provider_id":id,
        "brand_id":selectedBrandId

      }
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        console.log("Response:", res); // Debugging statement
        history.push('/dashboard');
      })
      .catch((err) => console.error("Error:", err)); // Improved error handling
  };

  return (
    <div className="d-flex w-100 vh-100">
      <div className="w-100 border pt-3 pb-5 rounded">
        <h1 className="updateuser">Add Support Brands </h1>
        {/* <form onSubmit={handleformsubmit}>
          <div className="row">
            <div className="mb-3 col-6">
              <label htmlFor="period">Brand Name:</label>
              <input
                type="text"
                name="brand_name"
                id="updateforminput"
                className="form-control"
                placeholder="Enter brand_name"
                onChange={(e) => setValues({ ...values, brand_name: e.target.value })}
              />
            </div>
     
          </div>
          <div className="pull-right">
            <button className="btn updatepagebtn mt-4">Add</button>
          </div>
           <Link to="/" className="btn btn-primary ms-3">
            Back
          </Link> 
        </form> */}

<label htmlFor="provider-select">Select Brand Name:</label>
<select className="form-select" value={selectedBrandId} onChange={handleChange}>
          <option value="">Select a provider</option>
          {data.map(item => (
            <option key={item.id} value={item.id}>
              {item.name} 
            </option>
          ))}
        </select>

        <div className="pull-right" style={{float:"right"}}>
            <button onClick={handleformsubmit} className="btn updatepagebtn mt-4">Add</button>
          </div>
        {
            console.log(selectedBrandId)
        }
      </div>

    </div>
  );
}
