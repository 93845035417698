import React, { useEffect, useState } from 'react'
import ProfileHeader from '../pages/Components'
import { Link, useHistory } from 'react-router-dom'
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import axios from 'axios';
import { useToken } from "../components/TokenContext";

export default function Addoption() {
  const { token } = useToken();
  const [values, setValues] = useState({
    option_name: "",
    service_id: "",
  });
  const history = useHistory();
  const [selectedService, setSelectedService] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [data, Setdata] = useState([]);


  useEffect(() => {
    axios
      .get(`https://carwashapis.gosmart.ae/get_all/carwash_service`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },})
      .then((res) => {
        Setdata(res.data.data);
      })
      .catch((err) => console.error("Error fetching service options data:", err));

  }, []);



  const handleChange = (event) => {
    setSelectedId(event.target.value);
    
  };


  const handleformsubmit = (event) => {
    event.preventDefault();
    axios.post(
      "https://carwashapis.gosmart.ae/create_record",
      {
        table_name: "option.provider",
        data: {
          option_name: values.option_name,
          service_id: selectedId
        }
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      console.log(res);
      history.push("/options");
    })
    .catch((err) => console.log(err));
  };
  
  

  return (
    <div>
      <ProfileHeader />
      <div className="title">
        <h1>        <Link to="/options"><img src={backbtn} style={{ marginRight: "1rem" }} /></Link>
          Add Option  </h1>

      </div>
      <form onSubmit={handleformsubmit}>
        <div className="add-btns d-flex justify-content-center align-items-center">
          {/* <Link to="/allservices">
    <button className="add-btn-back">Back</button>
  </Link> */}
        </div>

        <div className="mb-2">
          <label htmlFor="name" className="form-label">
            {" "}
            Option Name :
          </label>
          <input
            type="text"
            name="name"
            id="updateforminput"
            className="form-control"
            placeholder="Enter Name "
            onChange={(e) => setValues({ ...values, option_name: e.target.value })}
          />
        </div>



  

        <label htmlFor="provider-select">Select Service Name:</label>
        <select className="form-select" value={selectedId} onChange={handleChange}>
          <option value="">Select a provider</option>
          {data.map(item => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>

        <div className="pull-right">
          <button className="btn updatepagebtn mt-4">Submit</button>
        </div>

        {
          console.log(values.option_name)
        }

      </form>

    </div>
  )
}
