import { useState } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Home from './Users/Home';
import NotFound from './pages/NotFound';
import Components from './pages/Components';
import Profile from './pages/Profile';
import './styles.scss';
import Create from './Users/Create';
import Update from './Users/Updatetable';
import Operations from './Operations/Operations';
import FutureOperation from './Operations/FutureOperation';
import OldOperation from './Operations/OldOperation';
import Readuser from './Users/Readuser';
import AllServices from './Service/AllServices';
import Addservice from './Service/Addservice';
import ServiceInfo from './Service/ServiceInfo';
import SendEmail from './Mails/Sendemailform';
import Updateoperation from './Operations/updateoperation';
import Login from './pages/Login';
import UserArchive from './Users/UserArchive';
import Admin from './Clients/admin';
import Provider from './Provider/provider';
import Providerinfo from './Provider/Providerinfo';
import Subscriptions from './Subscription/Subscription';
import Payment from './payment/Payment';
import Packages from './packages/Packages';
import PackageInfo from './packages/PackageInfo';
import EditPackage from './packages/EditPackage';
import Editprovider from './Provider/editprovider';
import Main from './Home/main';
import Signup from './Home/Signup';
import Addpackage from './packages/Addpackage';
import Addprice from './packages/Addprice';
import UpdateArchive from './Users/UpdateArchive';
import ClientsArchive from './Clients/ClientsArchive';
import PaymentPage from './Home/pamentpage';
import Updatclient from './Clients/Updateclient';
import Addsupport from './Provider/Addsupport';
import SubRead from './Subscription/SubRead';
import Options from './Options/Options';
import Addoption from './Options/Addoption';
import UpdatePackage from './packages/UpdatePackage';
import ServiceArchive from './Service/ServiceArchive';
import UpdateArchiveService from './Service/Unarchive';
import OperationArchive from './Operations/OperationArchive';
import OperationUnArchive from './Operations/OperationUnArchive';
import AddFeature from './packages/AddFeature';
import ProtectedRoute from './components/ProtectedRoute';
import { TokenProvider } from "./components/TokenContext";


function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(false);
  const [toggled, setToggled] = useState(false);
  const location = useLocation();

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

   // List of routes where the sidebar should be hidden
   const sidebarHiddenRoutes = ['/login', '/signup', '/'];

   // Determine if the current route is the login route, sign-up route, or main route
   const isLoginRoute = location.pathname === '/login';
   const isSignUpRoute = location.pathname === '/signup';
   const isHomeRoute = location.pathname === '/';
   const isDashboardRoute = location.pathname === '/dashboard';
 

  return (
    <TokenProvider>
    <div className={`app ${toggled ? 'toggled' : ''}`}>
      {!sidebarHiddenRoutes.includes(location.pathname) && (
        <Sidebar
          image={image}
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />
      )}
   <main className={
        isLoginRoute ? 'main-login' : 
        isSignUpRoute ? 'main-signup' : 
        isHomeRoute ? 'main-component2' : 
        'main'
      }>        {!sidebarHiddenRoutes.includes(location.pathname) && (
          <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
            <FaBars />
          </div>
        )}
        <Switch>
        <Route path="/payment/:sessionid/:productId" component={PaymentPage} />
        {/* <Route path="/payment" component={PaymentPage} /> */}

          {/* <Route path="/components" component={Components} /> */}
          <ProtectedRoute path="/create" component={Create} />
          <ProtectedRoute path="/addoption" component={Addoption} />
          <ProtectedRoute path="/operationArchive" component={OperationArchive} />
          <ProtectedRoute path="/operationUnArchive/:id" component={OperationUnArchive} />
          <ProtectedRoute path="/addfeature/:id" component={AddFeature} />
          <ProtectedRoute path="/serviceArchive" component={ServiceArchive} />
          <ProtectedRoute path="/options" component={Options} />
          <ProtectedRoute path="/clients" component={Admin} />
          <ProtectedRoute path="/unarchiveService/:id" component={UpdateArchiveService} />
          <ProtectedRoute path="/adminunarchive/:id" component={Updatclient} />
          <ProtectedRoute path="/providers" component={Provider} />
          <ProtectedRoute path="/providersinfo/:id" component={Providerinfo} />
          <ProtectedRoute path="/subscriptions" component={Subscriptions} />
          <ProtectedRoute path="/payment" component={Payment} />
          <ProtectedRoute path="/packages" component={Packages} />
          <ProtectedRoute path="/addpackage" component={Addpackage} />
          <ProtectedRoute path="/addprice/:id" component={Addprice} />
          <ProtectedRoute path="/subscriberead/:id" component={SubRead} />
          <ProtectedRoute path="/addsupportbrand/:id" component={Addsupport} />
          <ProtectedRoute path="/update/:id" component={Update} />
          <ProtectedRoute path="/info/:id" component={Readuser} />
          <ProtectedRoute path="/packageinfo/:id" component={PackageInfo} />
          <ProtectedRoute path="/Updatepackage/:id" component={UpdatePackage} />
          <ProtectedRoute path="/Updatearchive/:id" component={UpdateArchive} />
          <ProtectedRoute path="/Editprovider/:id" component={Editprovider} />
          <ProtectedRoute path="/operations" component={Operations} />
          <ProtectedRoute path="/futureOperations" component={FutureOperation} />
          <ProtectedRoute path="/oldOperations" component={OldOperation} />
          <ProtectedRoute path="/allservices" component={AllServices} />
          <ProtectedRoute path="/addservice" component={Addservice} />
          <ProtectedRoute path="/userarchive" component={UserArchive} />
          <ProtectedRoute path="/clientsarchive" component={ClientsArchive} />
          <ProtectedRoute path="/serviceinfo/:id" component={ServiceInfo} />
          <ProtectedRoute path="/emails" component={SendEmail} />
          <ProtectedRoute path="/updateoperation/:id" component={Updateoperation} />
          <Route path="/login" component={Login} />
          <ProtectedRoute path="/dashboard" image={image} handleImageChange={handleImageChange} component={Home} />

          <Route path="/signup" component={Signup} />
          <Route path="/" exact>
            <Main />
          </Route>
          <Redirect to="/not-found" />
        </Switch>
        <Footer />
      </main>
    </div>
    </TokenProvider>
  );
}

export default App;