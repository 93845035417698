import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import ProfileHeader from '../pages/Components';
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import { useToken } from "../components/TokenContext";

export default function SubRead() {

  const { id } = useParams();
  const [values, setValues] = useState({});
  const [data, setData] = useState([]);
  const { token } = useToken();

  useEffect(() => {
    axios
      .get(`https://carwashapis.gosmart.ae/get_by_id/carwash_subscription/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setValues(res.data.data[0]))
      .catch((err) => console.log(res));
  }, []);

  useEffect(() => {
    axios
      .get(`https://carwashapis.gosmart.ae/details_of/carwash_invoice/subscription_id/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setData(res.data.data))
      .catch((err) => console.log(res));
  }, []);

  return (
    <div>
      <ProfileHeader />
      <div className="title">
        <h1>
          <Link to="/subscriptions"><img src={backbtn} style={{ marginRight: "1rem" }} /></Link>
          Subscription Info
        </h1>
      </div>

      <div className="grey-container">
        <div className="row">
          <div class="col-3">
            <span class="holder">ID :</span>

            <span className="values">{values.id}</span>
          </div>

          <div class="col-3 ">
            <span class="holder">Client Name :</span>
            <span className="values"> {values.client_name}</span>
          </div>

          <div class="col-3 ">
            <span class="holder">Email :</span>{" "}
            <span className="values">{values.email}</span>
          </div>
          <div class="col-3 ">
            <span class="holder">phone :</span>{" "}
            <span className="values">{values.phone}</span>
          </div>
          <div class="col-3 pt-5">
            {" "}
            <span class="holder">Start Date :</span>{" "}
            <span className="values">{values.subscription_start}</span>
          </div>
          <div class="col-3 pt-5">
            {" "}
            <span class="holder">End Date :</span>{" "}
            <span className="values">{values.subscription_end}</span>
          </div>
          <div class="col-3 pt-5">
            {" "}
            <span class="holder">Package Name :</span>{" "}
            <span className="values">{values.package_name}</span>
          </div>
          <div class="col-3 pt-5">
            {" "}
            <span class="holder">Payment Amount :</span>{" "}
            <span className="values">{values.payment_amount}</span>
          </div>
          <div class="col-3 pt-5">
            {" "}
            <span class="holder">Status :</span>{" "}
            <span className="values">{values.current_status}</span>
          </div>
          <div class="col-3 pt-5">
            {" "}
            <span class="holder">Center Name :</span>{" "}
            <span className="values">{values.center_name}</span>
          </div>
        </div>
      </div>

      <div className="car-header">Invoice info</div>

<table class="table mt-4">
  <thead>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Invoice Amount</th>
      <th scope="col">Amount Currency</th>
      <th scope="col">Status</th>
      <th scope="col">Paid At </th>
      <th scope="col">Period From</th>
      <th scope="col">Period To</th>
      <th scope="col">Subscription ID</th>
      <th scope="col">Invoive ID</th>
    </tr>
  </thead>
  <tbody>
    {data.map((d, i) => (
      <tr key={i}>
        <td>{d.id}</td>
        <td>{d.invoice_amount}</td>
        <td>{d.amount_currency}</td>
        <td>{d.status}</td>
        <td>{d.paid_at}</td>
        <td>{d.period_from}</td>
        <td>{d.period_to}</td>
        <td>{d.subscription_id}</td>
        <td>{d.invoice_id}</td>
      </tr>
    ))}
  </tbody>
</table>



    </div>
  );
}
