import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import deletee from "/webapps/carwash_landing/src/assets/Frame 1770.png";
import Modal from "react-modal";
import edit from "/webapps/carwash_landing/src/assets/edit-text.png";
import save from "/webapps/carwash_landing/src/assets/pngwing.com (2).png";
import { useToken } from "../components/TokenContext";

const SupportBrands = ({ content }) => {
  const { id } = useParams();
  const [supportbrands, Setsupport] = useState([]);
  const [editIndex2, setEditIndex] = useState(null);
  const [editData2, setEditData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { token } = useToken();

  const openModal = (id) => {
    setDeleteId(id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDelete = (id) => {
    axios
      .delete("https://carwashapis.gosmart.ae/delete_record/support.brands/" + id,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Setsupport(supportbrands.filter((user) => user.id !== id)); // Remove the deleted user from the data array
        setIsOpen(false); // Close the modal
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (content === "SupportBrands") {
      axios
        .get(`https://carwashapis.gosmart.ae/details_of/support_brands/provider_id/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          Setsupport(res.data.data);
        })
        .catch((err) => console.error("Error fetching service options data:", err));
    }
  }, [id, content]);

  const handleEditClick2 = (index) => {
    setEditIndex(index);
    setEditData(supportbrands[index]);
  };

  const handleSaveClick2 = (index) => {
    const originalData = supportbrands[index];
    const updatedFields = getUpdatedFields(originalData, editData2);
    if (Object.keys(updatedFields).length > 0) {
      handleUpdate2(editData2.id, updatedFields);
    } else {
      setEditIndex(null);
    }
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getUpdatedFields = (original, updated) => {
    const updatedFields = {};
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        updatedFields[key] = updated[key];
      }
    }
    return updatedFields;
  };

  const handleUpdate2 = (id, values) => {
    axios.post("https://carwashapis.gosmart.ae/Update_record", {
      object_id: id,
      table_name: "support.brands",
      data: values,
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res);
        const updatedData = [...supportbrands];
        updatedData[editIndex2] = { ...updatedData[editIndex2], ...values };
        Setsupport(updatedData);
        setEditIndex(null);
      })
      .catch((err) => console.log(err));
  };

  return (

    <div id="content2" style={{ display: content === "SupportBrands" ? "block" : "none" }}>

      <div className="mt-5 pb-5">

        <div className="d-flex justify-content-end">
          <span className="adduserbtn">
            <Link to={`/addsupportbrand/${id}`}>+ Add </Link>
          </span>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Brand Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {supportbrands.map((d, i) => (
              <tr key={i}>

                <td>
                  {editIndex2 === i ? (
                    <input
                      className="updateinputs"
                      type="text"
                      name="brand_name"
                      value={editData2.brand_name || ""}
                      onChange={handleChange2}
                    />
                  ) : (
                    d.brand_name
                  )}
                </td>
                <td>
                  {editIndex2 === i ? (
                    <button className="btn btn-sm editbtn" onClick={() => handleSaveClick2(i)}>
                      <img src={save} className="editbackground" />

                    </button>
                  ) : (
                    <button className="btn btn-sm editbtn" onClick={() => handleEditClick2(i)}>
                      <img src={edit} className="editbackground" />
                    </button>
                  )}

                  <button
                    className=" btn btn-sm deletebtn"
                    onClick={() => openModal(d.id)}
                  >
                    <img src={deletee} className="editbackground" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          className="Modal shadow"
        >
          <h2>Confirm Delete</h2>
          <p>Are you Sure you want to delete this record .</p>
          <div className="btns">
            <button
              className="Confirm"
              onClick={() => {
                handleDelete(deleteId);
                setIsOpen(false);
              }}
            >
              Confirm
            </button>
            <button class="cancel" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default SupportBrands;
