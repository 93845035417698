import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import "../styles/Service.css";
import ProfileHeader from "../pages/Components";
import Edittables from "./edittables";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import Modal from "react-modal";
import { useToken } from "../components/TokenContext";

const useAutoResizeTextarea = (textareaRef, value) => {
  useEffect(() => {
    const handleResize = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [textareaRef, value]);
};

export default function ServiceInfo() {
  const [data, setData] = useState({});
  const { id } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [originalValues, setOriginalValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const history = useHistory();
  const textareaRef = useRef(null);
  const { token } = useToken();

  useEffect(() => {
    axios
      .get(`https://carwashapis.gosmart.ae/get_by_id/carwash_service/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.data) {
          setData(res.data.data[0]);
          setOriginalValues(res.data.data[0]);
        } else {
          console.error("Unexpected response structure:", res);
        }
      })
      .catch((err) => console.error("Error fetching data:", err));
  }, [id]);

  useAutoResizeTextarea(textareaRef, data.description);

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const handleUpdate = (event) => {
    event.preventDefault();
  
    const updatedFields = {};
    Object.keys(data).forEach((key) => {
      if (data[key] !== originalValues[key]) {
        updatedFields[key] = data[key];
      }
    });
  
    axios
      .post("https://carwashapis.gosmart.ae/Update_record", {
        object_id: id,
        table_name: "carwash.service",
        data: updatedFields,
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsEditing(false);
  
        if (res.data && res.data.success) {
          console.log("Update successful:", res.data);
          // Reload the page after successful update
          history.go(0); // or history.push(location.pathname); or history.replace(location.pathname);
        } else {
          console.error("Update failed:", res.data);
        }
      })
      .catch((err) =>
        console.error("Error updating record:", err.response || err)
      );
  };
  
  

  const openModal = (id) => {
    setDeleteId(id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    window.location.reload();
  };

  const handleDelete = (id, event) => {
    axios
      .delete("https://carwashapis.gosmart.ae/delete_record/carwash.service/" + id,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(data.filter((user) => user.id !== id));
        setIsOpen(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <ProfileHeader />
      <div className="title">
        <h1>
          <Link to="/allservices">
            <img src={backbtn} style={{ marginRight: "1rem" }} alt="Back" />
          </Link>
          Service Details
        </h1>
      </div>
      <div className="top-btn">
        <button className="btn-edit-service" onClick={isEditing ? handleUpdate : toggleEditMode}>
          {isEditing ? "Update" : "Edit"}
        </button>
        <button className="go-back" onClick={() => openModal(id)}>
          Delete
        </button>
      </div>
      <div className="wrap">
        <div className="">
          <div>
            <label htmlFor="inputPassword5" className="form-label">
              Name
            </label>
            <input
              value={data.name || ""}
              disabled={!isEditing}
              type="text"
              id="inputPassword30"
              className="form-control"
              aria-describedby="passwordHelpBlock"
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </div>
          <div className="pt-4">
            <label htmlFor="inputPassword5" className="form-label">
              Time
            </label>
            <input
              value={`${data.time || ""} Minutes`}
              disabled={!isEditing}
              type="text"
              id="inputPassword30"
              className="form-control"
              aria-describedby="passwordHelpBlock"
              onChange={(e) => setData({ ...data, time: e.target.value })}
            />
          </div>
          <div className="pt-4 Dsc-sec">
            <label htmlFor="textarea1" className="inputPassword5">
              Description:
            </label>
            <textarea
              name="description"
              className="form-control3"
              id="inputPassword30"
              ref={textareaRef}
              value={data.description || ""}
              disabled={!isEditing}
              style={{
                width: "45.8%",
                overflow: "hidden", // Hide the scrollbar
                resize: "none", // Prevent manual resizing
                padding:"1rem"
              }}
              onChange={(e) => setData({ ...data, description: e.target.value })}
            />
          </div>
        </div>
      </div>
      <Edittables />
      <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel="Example Modal" className="Modal shadow">
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this record?</p>
        <div className="btns">
          <Link to="/allservices">
            <button
              className="Confirm"
              onClick={() => {
                handleDelete(deleteId);
                setIsOpen(false);
              }}
            >
              Confirm
            </button>
          </Link>
          <button className="cancel" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
}
