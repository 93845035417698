import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import deletee from "/webapps/carwash_landing/src/assets/Frame 1770.png";
import Modal from "react-modal";
import edit from "/webapps/carwash_landing/src/assets/edit-text.png";
import save from "/webapps/carwash_landing/src/assets/pngwing.com (2).png";
import { useToken } from "../components/TokenContext";

const PackagePricing = ({ content }) => {
  const { id } = useParams();
  const [deleteId, setDeleteId] = useState(null);
  const [deletePrice, setDeletePrice] = useState(null);
  const [deletePackageName, setDeletePackageName] = useState(null);
  const [pricing, Setpricing] = useState([]);
  const [editIndex2, setEditIndex] = useState(null);
  const [editData2, setEditData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { token } = useToken();

  const openModal = (packageName, price) => {
    setDeletePackageName(packageName);
    setDeletePrice(price);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (content === "PackagePricing") {
      axios
        .get(`https://carwashapis.gosmart.ae/details_of/package_pricing/package_id/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          Setpricing(res.data.data);
          console.log("Package data:", res.data.data); // Log the package data
        })
        .catch((err) => console.error("Error fetching service options data:", err));
    }
  }, [id, content]);

  const handleEditClick2 = (index) => {
    setEditIndex(index);
    setEditData(pricing[index]);
  };

  const handleSaveClick2 = (index) => {
    const originalData = pricing[index];
    const updatedFields = getUpdatedFields(originalData, editData2);
    if (Object.keys(updatedFields).length > 0) {
      handleUpdate2(editData2.id, updatedFields);
    } else {
      setEditIndex(null);
    }
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getUpdatedFields = (original, updated) => {
    const updatedFields = {};
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        updatedFields[key] = updated[key];
      }
    }
    return updatedFields;
  };

  const handleUpdate2 = (id, values) => {
    axios.post("https://carwashapis.gosmart.ae/Update_record", {
      object_id: id,
      table_name: "package.pricing",
      data: values,
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res);
        const updatedData = [...pricing];
        updatedData[editIndex2] = { ...updatedData[editIndex2], ...values };
        Setpricing(updatedData);
        setEditIndex(null);
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = (packageName, price) => {
    console.log("Deleting package:", packageName, "with price:", price); // Log the values being passed
    axios
    .post('https://carwashapis.gosmart.ae/delete_price_package', {
      package_name: packageName,
      price: price
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })      .then((res) => {
        const updatedPricing = pricing.filter((item) => item.package_name !== packageName || item.price !== price);
        Setpricing(updatedPricing);
        setIsOpen(false); // Close the modal
        console.log("Deleted package:", packageName, "with price:", price);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div id="content2" style={{ display: content === "PackagePricing" ? "block" : "none" }}>
      <div className="mt-5 pb-5">
        <div className="d-flex justify-content-end">
          <span className="adduserbtn">
            <Link to={`/addprice/${id}`}>+ Add </Link>
          </span>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Package Period [Months]</th>
              <th>Package Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {pricing.map((d, i) => (
              <tr key={i}>
                <td>
                  {editIndex2 === i ? (
                    <input
                      type="text"
                      name="period"
                      className="updateinputs"
                      value={editData2.package_period || ""}
                      onChange={handleChange2}
                    />
                  ) : (
                    d.package_period
                  )}
                </td>
                <td>
                  {editIndex2 === i ? (
                    <input
                      type="text"
                      name="price"
                      className="updateinputs"
                      value={editData2.price || ""}
                      onChange={handleChange2}
                    />
                  ) : (
                    <span>{d.price} $</span>
                    )}
                </td>
                <td>
                  {editIndex2 === i ? (
                    <button className="btn btn-sm editbtn mr-2" onClick={() => handleSaveClick2(i)}>
                                        <img src={save} className="editbackground" />

                    </button>
                  ) : (
                    <button className="btn btn-sm editbtn mr-2" onClick={() => handleEditClick2(i)}>
                      <img src={edit} className="editbackground" />
                    </button>
                  )}
                  <button
                    className="btn btn-sm deletebtn "
                    onClick={() => openModal(d.package_name, d.price)}
                  >
                    <img src={deletee} className="editbackground" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          className="Modal shadow"
        >
          <h2>Confirm Delete</h2>
          <p>Are you sure you want to delete this record?</p>
          <p>Package Name: {deletePackageName}</p>
          <p>Price: {deletePrice}</p>
          <div className="btns">
            <button
              className="Confirm"
              onClick={() => handleDelete(deletePackageName, deletePrice)}
            >
              Confirm
            </button>
            <button className="cancel" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default PackagePricing;
