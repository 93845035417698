import React, { useEffect } from "react";
import "./WebStyles/footer.css";
// import google from "/home/user/lms/src/assets/google.svg";
 import googleapp from "./WebAssets/google play.png";
 import appstore from "./WebAssets/app store.png";
 import appgallery from "./WebAssets/app gallery.png";
 import  socialimg from "./WebAssets/Frame 1735.png";
 import  Email from "./WebAssets/email (2) 1.png";
 import { configureAnchors } from 'react-scrollable-anchor';
import ScrollableAnchor from 'react-scrollable-anchor';
// import linkedin from "/home/user/lms/src/assets/linkedin.svg";
// import facebook from "/home/user/lms/src/assets/facebook.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import socialicon from "/home/user/lms/src/assets/social-footer.png"

configureAnchors({ scrollDuration: 5000 });

export default function Footer() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <ScrollableAnchor id={'footer'}>
    <div className="footer-wrapper">
    <footer id="footer" className="container">
      <div class=" pt-5 pb-5 footer">
        <div class="container-fluid upper-sec">
          <div class="row">
            <div class="col-xl-3 col-5  footer-list-div pt-2">
              <h1>Car Wash</h1>
              <p class="pt-1 footer-left-text">
              Download the app today <br/> Your car cleaner is at your <br/> fingertips!
              </p>
        
            </div>
            <div class="footer-list-div col-xl-2 col-4  links pt-3">
              <h6>CAR WASH</h6>
             <div class="mobapps">
              <img src={googleapp} />
              <img src={appstore} />
              <img src={appgallery} />
             </div>
            </div>
            <div class="footer-list-div col-xl-2 col-3  links pt-3">
              <h6>Quick Links</h6>
              <div class="flexing">
             <a>Home</a>
             <a>Features</a>
             <a>Pricing</a>
             </div>
            </div>
            <div class="footer-list-div col-xl-2 col-5  links pt-3" id="paddingexample">
              <h6>Privacy</h6>
              <div  class="flexing">
              <a>Privacy Policy</a>
             <a>Terms And Conditions</a>
             </div>
            </div>
            <div class="footer-list-div col-xl-3 col-7  links pt-3"id="paddingexample">
              <h6 >Follow Us</h6>
                <img src={socialimg} />
                <p className="mb-0"id="paddingexample2">lets talk</p>
                <div class="fax">
                  <img  src={Email} />
                <span>super@carwash_App</span>

                </div>
            </div>
          </div>

          <div class="last-section row ">
            <div class=" col-xl-12 col-12 ">
              <p class="pt-2">Copyright © 2024 CARWASH.app</p>
            </div>
          </div> 
         
        </div>
      
      </div>
    </footer>
    </div>
    </ScrollableAnchor>

  );
}
