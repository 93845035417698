import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const loggedInEmail = sessionStorage.getItem('loggedInEmail');
  const loginTime = sessionStorage.getItem('loginTime');
  const SESSION_DURATION = 120000; // 2 minutes in milliseconds

  const isAuthenticated = () => {
    if (loggedInEmail && loginTime) {
      const currentTime = new Date().getTime();
      return currentTime - loginTime < SESSION_DURATION;
    }
    return false;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default ProtectedRoute;
