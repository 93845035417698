import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import { useToken } from "../components/TokenContext";

export default function AddFeature() {
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState({
    featuers:"",
    package_id:id
  });
  const { token } = useToken();


  const handleformsubmit = (event) => {
    event.preventDefault();
    console.log("Form Values:", values); // Debugging statement
  
    axios.post(
      "https://carwashapis.gosmart.ae/create_record",
      {
        table_name: "package.featuers",
        data: values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(res => {
      console.log("Response:", res); // Debugging statement
      history.push(`/packageinfo/${id}`);
    })
    .catch(err => console.error("Error:", err)); // Improved error handling
  };
  

  return (
    <div className="d-flex w-100 vh-100">
      <div className="w-100 border pt-3 pb-5 rounded">
      <div className="title">
          <h1>
            <Link to={`/packageinfo/${id}`}><img src={backbtn} style={{ marginRight: "1rem" }} /></Link>
            Add Feature 
          </h1>
        </div>        <form onSubmit={handleformsubmit}>
          <div className="row">

            <div className="mb-3 col-6">
              <label htmlFor="period">Feature : </label>
              <input
                type="text"
                name="featuers"
                id="updateforminput"
                className="form-control"
                placeholder="Enter Feature"
                onChange={(e) => setValues({ ...values, featuers: e.target.value })}
              />
            </div>
         
          </div>
          <div className="pull-right">
            <button className="btn updatepagebtn mt-4" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Add'}
            </button>
          </div>
          {/* <Link to="/" className="btn btn-primary ms-3">
            Back
          </Link> */}
        </form>
      </div>

    </div>
  );
}
