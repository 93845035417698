import React from "react";
import ProfileHeader from "../pages/Components";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { useToken } from "../components/TokenContext";

export default function Payment() {

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const { token } = useToken();

  useEffect(() => {
    axios
      .get("https://carwashapis.gosmart.ae/get_all/carwash_payment",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => setData(res.data.data))
      .catch((err) => console.log(res));
  }, []);

  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedItems.indexOf(id);
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    }
  };
  return (
    <div>
      <ProfileHeader />
      <h1 className="updateuser">Payments</h1>
      <table class="table shadow">
        <thead>
          <tr className="shadow">
            <th scope="col">ID</th>
            <th scope="col">Booking ID</th>
            <th scope="col">Payment Method </th>
            <th scope="col">Price </th>
            <th scope="col">Provider Name</th>      
            <th scope="col">Payment Date </th>
            <th scope="col">Status</th>

            {/* <th scope="col">Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              <td>{d.id}</td>
              <td>{`Booking #${d.booking_id}`}</td>
              <td>{d.payment_method_name}</td>
              <td>{`${d.price} $ `}</td>
              <td>{d.provider_name}</td>
              <td>{d.date_at}</td>
              <td>{d.status}</td>
              {/* <td>
                <input
                  type="checkbox"
                  checked={d.is_archive}
                  onChange={() => handleCheckboxChange(d.id)}
                  disabled={true}
                />
              </td> */}
              {/* <td>Un Archive</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
