import React, { useState, useEffect } from "react";
import ProfileHeader from "../pages/Components";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import edit from "/webapps/carwash_landing/src/assets/edit-text.png";
import deletee from "/webapps/carwash_landing/src/assets/Frame 1770.png";
import Modal from "react-modal";
import view from "/webapps/carwash_landing/src/assets/view-icon.png";
import { useToken } from "../components/TokenContext";

export default function Provider() {
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const { token } = useToken();


  const openModal = (id) => {
    setDeleteId(id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    axios
      .get("https://carwashapis.gosmart.ae/get_all/carwash_provider",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => setData(res.data.data))
      .catch((err) => console.log(res));
  }, []);

  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedItems.indexOf(id);
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    }
  };


  const handleDelete = (id) => {
    axios
      .delete("https://carwashapis.gosmart.ae/delete_record/carwash.provider/" + id)
      .then((res) => {
        setData(data.filter((value) => value.id !== id)); // Remove the deleted user from the data array
        setIsOpen(false); // Close the modal
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .catch((err) => console.log(err));
      window.location.reload();

  };

  return (
    <div>
      <ProfileHeader />
      <div className="title">
        <h1>Centers</h1>
      </div> 
      <table class="table w-100">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Rating</th>
            <th scope="col">Is Archive</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              <td>{d.id}</td>
              <td>{d.name}</td>
              <td>{d.rating}</td>
              <td>
                <input
                  type="checkbox"
                  checked={d.is_archive}
                  onChange={() => handleCheckboxChange(d.id)}
                  disabled={true}

                />
              </td>          
        
              <td>
                <Link to={`/providersinfo/${d.id}`}>
                <button className="btn btn-sm readbtn ">
                  <img src={view} className="viewicon" />
                  </button>                </Link>
                <Link to={`/Editprovider/${d.id}`}>
                  <button className="btn btn-sm editbtn">
                    <img src={edit} className="editbackground" />
                  </button>
                </Link>
                <button
                  className="btn btn-sm deletebtn"
                  onClick={() => openModal(d.id)}
                >
                  <img src={deletee} className="editbackground" />
                </button>
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="Modal shadow"
      >
        <h2>Confirm Delete</h2>
        <p>Are you Sure you want to delete this record .</p>
        <div className="btns">
          <button
            className="Confirm"
            onClick={() => {
              handleDelete(deleteId);
              setIsOpen(false);
            }}
          >
            Confirm
          </button>
          <button class="cancel" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
}
