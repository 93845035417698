import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../styles/Read.css";
import ProfileHeader from "../pages/Components";
import SupportBrands from "./supportbrands";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import view from "/webapps/carwash_landing/src/assets/view-icon.png";
import edit from "/webapps/carwash_landing/src/assets/edit-text.png";
import save from "/webapps/carwash_landing/src/assets/pngwing.com (2).png";
import { useToken } from "../components/TokenContext";

export default function Providerinfo() {
  const [activeTag, setActiveTag] = useState("tag1");
  const [content, setContent] = useState("ProviderRating");
  const { id } = useParams();
  const [values, setValues] = useState({});
  const textAreaRef = useRef(null);
    const [editIndex, setEditIndex] = useState(null);
    const [editData, setEditData] = useState({});
    const [ProviderRating, SetproviderRating] = useState([]);
    const { token } = useToken();

  const changeContent = (id, tagname) => {
    switch (id) {
      case "Provider-Rating":
        setContent("ProviderRating");
        break;
      case "Support-Brands":
        setContent("SupportBrands");
        break;
      default:
        setContent("");
        break;
    }
    setActiveTag(tagname);
  };
  useEffect(() => {
    axios.get(`https://carwashapis.gosmart.ae/get_by_id/carwash_provider/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setValues(res.data.data[0]);
      })
      .catch((err) => console.log(err));
  }, [id]);
   useEffect(() => {
    if (content === "ServiceProvider") {
      axios
        .get(`https://carwashapis.gosmart.ae/details_of/service_provider/service_id/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setServicePro(res.data.data);
        })
        .catch((err) => console.error("Error fetching service provider data:", err));
    } 
  }, [id, content]);

  useEffect(() => {
    if (content === "ProviderRating") {
      axios
        .get(`https://carwashapis.gosmart.ae/details_of/provider_rating/provider_id/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
            SetproviderRating(res.data.data);
        })
        .catch((err) => console.error("Error fetching service provider data:", err));
    } 
  }, [id, content]);


useEffect(() => {
  if (textAreaRef.current) {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  }
}, [values.description ]);
 useEffect(() => {axios.get(`https://carwashapis.gosmart.ae/get_by_id/carwash_provider/${id}`,{
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

.then((res) => setValues(res.data.data[0]))
.catch((err) => console.log(res));
 }, []);

 const handleUpdate = (event) => {
  event.preventDefault();
  axios.post("https://carwashapis.gosmart.ae/Update_record", {
  object_id:id,
  table_name:"carwash.provider",
  data:values
  },{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
.then(res => {console.log(res);
  history.push('/packages');
})
.catch((err) => console.log(res));
};

const handleEditClick = (index) => {
    setEditIndex(index);
    setEditData(ProviderRating[index]);
  };

  const handleSaveClick = (index) => {
    const originalData = ProviderRating[index];
    const updatedFields = getUpdatedFields(originalData, editData);
    if (Object.keys(updatedFields).length > 0) {
      handleUpdate2(editData.id, updatedFields);
    } else {
      setEditIndex(null);
    }
  };


const handleChange = (e) => {
const { name, value } = e.target;
setEditData((prevData) => ({ ...prevData, [name]: value }));
};

const getUpdatedFields = (original, updated) => {
const updatedFields = {};
for (const key in updated) {
  if (updated[key] !== original[key]) {
    updatedFields[key] = updated[key];
  }
}
return updatedFields;
};


const handleUpdate2 = (id, values) => {
axios.post("https://carwashapis.gosmart.ae/Update_record", {
  object_id: id,
  table_name: "provider.rating",
  data: values,
},{
  headers: {
    Authorization: `Bearer ${token}`,
  },
})
.then((res) => {
  console.log(res);
  const updatedData = [...ProviderRating];
  updatedData[editIndex] = { ...updatedData[editIndex], ...values };
  SetproviderRating(updatedData);
  setEditIndex(null);
})
.catch((err) => console.log(err));
};
  return (
    <div>
      <div className="d-flex w-100 vh-100 justify-content-center align-items-center">
        <div className="w-100 h-100 pt-3 pb-5 rounded">
        <ProfileHeader />
        <div className="title">
          <h1>        <Link to="/providers"><img src={backbtn} style={{ marginRight: "1rem" }} /></Link>
             Center Info</h1>

        </div>    
          <div className="grey-container">
            <div className="row">
             
              <div className="col-3">
                <span className="holder">Name : </span>
                <span className="values">{values.name}</span>
              </div>
              <div className="col-3">
                <span className="holder">Email : </span>
                <span className="values">{values.email}</span>
              </div>
              <div className="col-3">
                <span className="holder">Phone : </span>
                <span className="values">{values.phone}</span>
              </div>
              <div className="col-3 ">
                <span className="holder">Capacity : </span>
                <span className="values">{values.capacity}</span>
              </div>
           
              <div className="col-3 pt-5">
                <span className="holder">Latitude : </span>
                <span className="values">{values.latitude}</span>
              </div>
              <div className="col-3 pt-5">
                <span className="holder">Longitude : </span>
                <span className="values">{values.longitude}</span>
              </div>
              <div className="col-3 pt-5">
                <span className="holder">Location : </span>
                <span className="values">{values.location}</span>
              </div>
              <div className="col-3 pt-5">
                <span className="holder">Rating : </span>
                <span className="values">{values.rating}</span>
              </div>
              <div className="col-3 pt-5">
                <span className="holder">Image : </span>
                <img src={values.image} width="100w"  />
              </div>
              <div className="col-3 pt-5">
                <span className="holder">Working From : </span>
                <span className="values">{values.working_from}</span>
              </div>
              <div className="col-3 pt-5">
                <span className="holder">Working To : </span>
                <span className="values">{values.working_to}</span>
              </div>
            <div className="col-3 pt-5">
                <Link to="/clients" >
                  <span style={{ color: "blue" }} className="holder">Client Name : </span>
                  <span className="values">{values.client_name}</span>

                </Link>
                </div>
              <div className="col-12 pt-5">
                <span className="holder">Description : </span>
                <span className="values">{values.description}</span>
              </div>
            </div>
          </div>
          <div className="menu-list  w-100">
        <ul>
          <li
            onClick={() => changeContent("Provider-Rating", "tag1")}
            className={`List-item3 ${activeTag === "tag1" ? "active" : ""}`}
          >
            <button className="all">Provider Ratings</button>
          </li>
          <li
            onClick={() => changeContent("Support-Brands", "tag2")}
            className={`List-item3 ${activeTag === "tag2" ? "active" : ""}`}
          >
            <a>Support Brands</a>
          </li>
       
        </ul>
      </div>

      <div
        id="content1"
        style={{ display: content === "ProviderRating" ? "block" : "none" }}
      >
        <div className="mt-5 pb-5">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Center Name</th>
                <th>Rating</th>
                <th>Client Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {ProviderRating.map((d, i) => (
                <tr key={i}>
                  <td>{d.id}</td>
                  <td>
                    {editIndex === i ? (
                      <input
                      className="updateinputs"
                        type="text"
                        name="provider_name"
                        value={editData.provider_name || ""}
                        onChange={handleChange}
                      />
                    ) : (
                      d.provider_name
                    )}
                  </td>

                  <td>
                    {editIndex === i ? (
                      <input
                        type="text"
                        name="last_rating"
                        value={editData.last_rating || ""}
                        onChange={handleChange}
                        className="updateinputs"
                      />
                    ) : (
                      d.last_rating
                    )}
                  </td>
                  <td>
                    {editIndex === i ? (
                      <input
                        type="text"
                        name="user_name"
                        value={editData.user_name || ""}
                        onChange={handleChange}
                        className="updateinputs"
                      />
                    ) : (
                      d.user_name
                    )}
                  </td>
                  <td>
                    {editIndex === i ? (
                      <button
                        className="btn btn-sm editbtn mr-2"
                        onClick={() => handleSaveClick(i)}
                      >
                        Save
                      </button>
                    ) : (

                      <button className="btn btn-sm editbtn "
                      onClick={() => handleEditClick(i)}
                      >
                      <img src={edit} className="editbackground" />
                    </button>
                  
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <SupportBrands
        handleChange={handleChange}
        content={content}
        activeTag={activeTag}
      />

        </div>
      </div>
    </div>
  );
}
