import React from 'react'
import "../styles/Header.css"
import profilepic from "../assets/profile-picture.png"

export default function ProfileHeader() {


	const handleLogout = () => {
		sessionStorage.clear();
		history.push("/login");
	};


	return (
		<div>
			<>

				<div class="row">

					<div class="col-md-6 col-sm-8 clearfix">

						<ul class="user-info pull-left pull-none-xsm">

							<li class="profile-info dropdown">

								<a href="#" class="dropdown-toggle" data-toggle="dropdown">
									<img src={profilepic} alt="" class="img-circle" width="44" />
									John Henderson
								</a>

								<ul class="dropdown-menu">

									<li class="caret"></li>

									<li>
										<a href="extra-timeline.html">
											<i class="entypo-user"></i>
											Edit Profile
										</a>
									</li>

									<li>
										<a href="mailbox.html">
											<i class="entypo-mail"></i>
											Inbox
										</a>
									</li>

									<li>
										<a href="extra-calendar.html">
											<i class="entypo-calendar"></i>
											Calendar
										</a>
									</li>

									<li>
										<a href="#">
											<i class="entypo-clipboard"></i>
											Tasks
										</a>
									</li>
								</ul>
							</li>

						</ul>



					</div>


					<div class="col-md-6 col-sm-4 clearfix hidden-xs">

						<ul class="list-inline links-list pull-right " style={{ float: "right" }}>


							<li>
							<a href="#" onClick={handleLogout}>
									Log out <i class="entypo-logout right"></i>
								</a>
							</li>
						</ul>

					</div>

				</div>





			</>
		</div>
	)
}



