import React, { useEffect, useState } from "react";
import "./WebStyles/business.css";
import businessplan from "./WebAssets/Asset 1@4x 2.png";
import checkmark from "./WebAssets/check-box 1.png";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Businessplan() {
  const [data, setData] = useState([]);
  const [packageDetails, setPackageDetails] = useState("");
  const [features, SetFeatures] = useState([]);
  const [token, setToken] = useState(null);


  const fetchToken = async () => {
    try {
      // Step 1: Get phone data from guest mode
      const guestResponse = await axios.get("https://carwashapis.gosmart.ae/get_guest_mode_data/");
      const phone = guestResponse.data.data[0].phone; // Assuming phone is returned in the response

      // Step 2: Use phone data to get the token
      const loginResponse = await axios.post("https://carwashapis.gosmart.ae/user_login", {
        login: phone,
        flag: true
      });
      // console.log(loginResponse.data.access_token)
      const token = loginResponse.data.access_token; // Assuming token is returned in the response

      setToken(token); // Store the token in state
    } catch (error) {
      console.error("Error fetching the token:", error);
    }
  };

  // Fetch the token on component mount
  useEffect(() => {
    fetchToken();
  }, []);

 // Fetch package details after token is available
 useEffect(() => {
  if (!token) return; // Wait for token to be available
  // console.log(token)
  const fetchData = async () => {
    try {
      // Fetch carwash package details
      const packageResponse = await axios.get("https://carwashapis.gosmart.ae/get_all/carwash_package", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log(packageResponse)
      const fetchedPackageData = packageResponse.data.data[0];
      setData(fetchedPackageData);

      // Fetch package features
      const featuresResponse = await axios.get("https://carwashapis.gosmart.ae/get_all/package_featuers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(featuresResponse)
      const fetchedFeaturesData = featuresResponse.data.data[0];
      SetFeatures(fetchedFeaturesData);
      setPackageDetails(fetchedFeaturesData.featuers);
    } catch (error) {
      console.error("Error fetching package or feature details:", error);
    }
  };

  fetchData();
}, [token]);


  

  // useEffect(() => {
  //   axios
  //     .get("https://carwashapis.gosmart.ae/get_all/carwash_package" , {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     } )
  //     .then((response) => {
  //       console.log(response)
  //       const fetchedData = response.data.data[0];
  //       setData(fetchedData);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the package details:", error);
  //     });
  // }, []);


  // useEffect(() => {


  //   axios.get("https://carwashapis.gosmart.ae/get_all/package_featuers", {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   }

  //   )

  //     .then((response) => {
  //       console.log(response)
  //       const fetchedData = response.data.data[0];
  //       SetFeatures(fetchedData);
  //       setPackageDetails(fetchedData.featuers);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the package details:", error);
  //     })

  // }

  //   , []);

  const detailsArray = packageDetails ? packageDetails.split(",") : [];

  return (
    <div>
      <div className="business-sec" id="Pricing">
        <div className="container business-sec-center-text">
          <h6>Your Business Plan</h6>
          <button class="mb-5"></button>
        </div>

        <div className="container business-sec-grid">
          <div className="business-sec-left">
            <img src={businessplan} />
          </div>

          <div className="business-sec-right">
            <div className="Plans-card">
              {data && (
                <div class="Plans-card-text">
                  <h5>Subscribe for {data.package_name} Plan</h5>
                  {/* <h6>{data.package_period}</h6>
                  <span>$ {data.package_cost}</span> */}
                  {/* <p className="pack-dsc">$ {features.featuers}</p> */}
                </div>
              )}

              <div className="check-sec">
                {detailsArray.map(
                  (detail, index) =>
                    detail && (
                      <div key={index} className="check-mark-card">
                        <p className="check-mar-card">
                          <img
                            src={checkmark}
                            alt="Checkmark"
                            style={{ marginRight: "8px" }}
                          />
                          {detail}
                        </p>
                      </div>
                    )
                )}

                <Link to="/signup"><button>Register Now </button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
