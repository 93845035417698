import React from "react";
import ProfileHeader from "../pages/Components";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import edit from "/webapps/carwash_landing/src/assets/edit-text.png";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import { useToken } from "../components/TokenContext";

export default function UserArchive() {

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const { token } = useToken();

  useEffect(() => {
    axios
      .get("https://carwashapis.gosmart.ae/get_all_archive/carwash_user",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => setData(res.data.data))
      .catch((err) => console.log(res));
  }, []);

  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedItems.indexOf(id);
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    }
  };


  return (
    <div>
      <ProfileHeader />
      <h1 className="updateuser">User Archives</h1>
      <table class="table shadow">
        <thead>
          <tr className="shadow">
            <th scope="col">Name</th>
            <th scope="col">ID</th>
            <th scope="col">Phone</th>
            <th scope="col">Latitude</th>
            <th scope="col">Longitude</th>
            <th scope="col">Password</th>
            <th scope="col">Confirm Password</th>
            <th scope="col">is Archive</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              <td>{d.name}</td>
              <td>{d.id}</td>
              <td>{d.phone}</td>
              <td>{d.latitude}</td>
              <td>{d.longitude}</td>
              <td>{d.password}</td>
              <td>{d.confirm_password}</td>
              <td>
                <input
                  type="checkbox"
                  checked={d.is_archive}
                  onChange={() => handleCheckboxChange(d.id)}
                />
              </td>
              <td >
              <Link to={`/Updatearchive/${d.id}`}>
                  <button className="btn btn-sm editbtn">
                    <img src={edit} className="editbackground"/>
                  </button>
                </Link>
                 </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
