import React, { useState } from "react";
import "../Home/WebStyles/Navbar.css";
import { Link, useHistory } from "react-router-dom";
import menutoggleicon from '../Home/WebAssets/Frame 1786.png';
import logo from '/webapps/carwash_landing/src/Home/WebAssets/df (2).png';

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useHistory();

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  function handleNavClick(section) {
    toggleMenu();
    history.push("/", { section });
  }

  return (
    <div className="navbar-wrapper">
      <nav className="navbar navbar-expand-lg navbar-light container">
        <div className="container-fluid">
          <Link to="/" style={{ textDecorationLine: "none" }}>
            <a className="navbar-brand" href="#">
              <img src={logo} style={{ width: "65px", height: "65px" }} alt="Logo" />
            </a>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex">
              <li className="nav-item">
                <Link to="/" style={{ textDecorationLine: "none" }}>
                  <a className="nav-link active" aria-current="page" href="#">
                    Home
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#Applications" onClick={() => handleNavClick('Applications')}>
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#Pricing" onClick={() => handleNavClick('Pricing')}>
                  Pricing
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#footer" onClick={() => handleNavClick('footer')}>
                  Contact Us
                </a>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto" style={{ marginLeft: "auto" }}>
              <li className="nav-item" id="signup-holder">
                <Link to="/signup">
                  <button className="signup-btn" href="#">
                    Sign up
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
