import React from "react";
import ProfileHeader from "../pages/Components";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import edit from "/webapps/carwash_landing/src/assets/edit-text.png";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import { useToken } from "../components/TokenContext";


export default function OperationArchive() {
    const { token } = useToken();
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        axios
          .get("https://carwashapis.gosmart.ae/get_all_archive/carwash_operation",{
            headers: {
              Authorization: `Bearer ${token}`,
            },})
          .then((res) => setData(res.data.data))
          .catch((err) => console.log(res));
      }, []);
    
      const handleCheckboxChange = (id) => {
        const selectedIndex = selectedItems.indexOf(id);
        if (selectedIndex === -1) {
          setSelectedItems([...selectedItems, id]);
        } else {
          setSelectedItems(selectedItems.filter((item) => item !== id));
        }
      };
  return (
    <div>
      <ProfileHeader />
      <h1 className="updateuser">Service Archives</h1>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">booking ID</th>
            <th scope="col">Provider Name</th>
            <th scope="col">date</th>
            <th scope="col">is Arrive</th>
            <th scope="col">Start</th>
            <th scope="col">End</th>
            <th scope="col">status</th>
            <th scope="col">is Exit</th>
            <th scope="col">action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              <td>{d.booking_id}</td>
              <td>{d.provider_name}</td>
              <td>{d.date}</td>

              <td>
                <input
                  type="checkbox"
                  checked={d.is_arrive}
                  onChange={() => handleCheckboxChange(d.id)}
                  disabled={true}
                />
              </td>
              <td>{d.start === "Failed" ? "" : d.start}</td>
              <td>{d.end === "Failed" ? "" : d.end}</td>
              <td>{d.status}</td>

              <td>
                <input
                  type="checkbox"
                  checked={d.is_exit}
                  onChange={() => handleCheckboxChange(d.id)}
                  disabled={true}
                />
              </td>
              <td>
                {/* <Link to={`/updateoperation/${d.id}`}>
                  <button className="btn btn-sm editbtn mr-2">
                    <img src={edit} className="editbackground" />
                  </button>
                </Link> */}
               <Link to={`/operationUnArchive/${d.id}`}>
                  <button className="btn btn-sm editbtn">
                    <img src={edit} className="editbackground"/>
                  </button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>  )
}
