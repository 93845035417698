import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useToken } from "../components/TokenContext";

const Content2 = ({ content }) => {
  const { id } = useParams();
  const [options, setServiceOptions2] = useState([]);
  const [editIndex2, setEditIndex] = useState(null);
  const [editData2, setEditData] = useState({});
  const { token } = useToken();

  useEffect(() => {
    if (content === "ServiceOptions") {
      axios
        .get(`https://carwashapis.gosmart.ae/details_of/service_options/service_id/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setServiceOptions2(res.data.data);
        })
        .catch((err) => console.error("Error fetching service options data:", err));
    }
  }, [id, content]);

  const handleEditClick2 = (index) => {
    setEditIndex(index);
    setEditData(options[index]);
  };

  const handleSaveClick2 = (index) => {
    const originalData = options[index];
    const updatedFields = getUpdatedFields(originalData, editData2);
    if (Object.keys(updatedFields).length > 0) {
      handleUpdate2(editData2.id, updatedFields);
    } else {
      setEditIndex(null);
    }
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getUpdatedFields = (original, updated) => {
    const updatedFields = {};
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        updatedFields[key] = updated[key];
      }
    }
    return updatedFields;
  };

  const handleUpdate2 = (id, values) => {
    axios.post("https://carwashapis.gosmart.ae/Update_record", {
      object_id: id,
      table_name: "service.options",
      data: values,
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res);
        const updatedData = [...options];
        updatedData[editIndex2] = { ...updatedData[editIndex2], ...values };
        setServiceOptions2(updatedData);
        setEditIndex(null);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div id="content2" style={{ display: content === "ServiceOptions" ? "block" : "none" }}>
      <div className="mt-5">
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Vehicle Type</th>
              <th>Price</th>
              <th>Center Name</th>
              <th>Option</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {options.map((d, i) => (
              <tr key={i}>
                <td>{d.id}</td>
      
                <td>
                  {editIndex2 === i ? (
                    <input
                      type="text"
                      name="vehicle_type_name"
                      className="updateinputs"
                      value={editData2.vehicle_type_name || ""}
                      onChange={handleChange2}
                    />
                  ) : (
                    d.vehicle_type_name
                  )}
                </td>
                <td>
                  {editIndex2 === i ? (
                    <input
                      type="text"
                      name="price"
                      className="updateinputs"
                      value={editData2.price || ""}
                      onChange={handleChange2}
                    />
                  ) : (
                    <span>{d.price} $</span>
                  )}
                </td>
                <td>
                  {editIndex2 === i ? (
                    <input
                      type="text"
                      name="provider_name"
                      className="updateinputs"
                      value={editData2.provider_name || ""}
                      onChange={handleChange2}
                    />
                  ) : (
                    d.provider_name
                  )}
                </td>
                <td>
                  {editIndex2 === i ? (
                    <input
                      type="text"
                      name="option"
                      className="updateinputs"
                      value={editData2.option || ""}
                      onChange={handleChange2}
                    />
                  ) : (
                    d.option
                  )}
                </td>
                <td>
                  {editIndex2 === i ? (
                    <button className="btn btn-primary" onClick={() => handleSaveClick2(i)}>
                      Save
                    </button>
                  ) : (
                    <button className="btn btn-secondary" onClick={() => handleEditClick2(i)}>
                      Archive
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Content2;
