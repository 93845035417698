import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../styles/updateform.css";
import ProfileHeader from "../pages/Components";
import { useToken } from "../components/TokenContext";

export default function Updateclient() {
  const history = useHistory();
  const { id } = useParams();
  const [values, setValues] = useState({ is_archive: "false" });
  const [error, setError] = useState('');
  const { token } = useToken();

  useEffect(() => {
    axios.get(`https://carwashapis.gosmart.ae/get_by_id/carwash_admin/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => setValues(res.data.data[0]))
      .catch((err) => console.log(err));
  }, [id]);

  const handleUpdate = (event) => {
    event.preventDefault();
    axios.post("https://carwashapis.gosmart.ae/Update_record", {
      object_id: id,
      table_name: "carwash.admin",
      data: { is_archive: values.is_archive }
    },
    {
      headers: {Authorization: `Bearer ${token}`,
      },
    }
    )
      .then(res => {
        console.log(res);
        history.push('/dashboard');
      })
      .catch((err) => {
        console.error(err);
        setError('An error occurred while updating the record.');
      });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setValues({ ...values, is_archive: value });
  };

  return (
    <div className="d-flex w-100 vh-100 ">
      <div className="w-100 border pt-3 pb-5 rounded">
        <ProfileHeader />
        <h1 className="updateuser">Update Archive Client</h1>
        <form onSubmit={handleUpdate}>
          <div className="row">
            <div className="mb-2 col-6">
              <label htmlFor="is_archive">is Archive:</label>
              <input
                type="text"
                name="is_archive"
                id="updateforminput"
                className="form-control"
                placeholder="Enter is_archive (true/false)"
                value={values.is_archive || false}
                onChange={handleInputChange}
              />
            </div>
          </div>
          {error && <p className="text-danger">{error}</p>}
          <div className="pull-right">
            <button className="btn updatepagebtn mt-4">Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}
