// Login.js
import React, { useState, useEffect } from "react";
import car from "/webapps/carwash_landing/src/assets/df.png";
import "../styles/login.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useToken } from "../components/TokenContext";

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();
  const { setToken } = useToken();
  const SESSION_DURATION = 120000; // 2 minutes in milliseconds

  useEffect(() => {
    const loggedInEmail = sessionStorage.getItem('loggedInEmail');
    const loginTime = sessionStorage.getItem('loginTime');

    if (loggedInEmail && loginTime) {
      const currentTime = new Date().getTime();
      if (currentTime - loginTime < SESSION_DURATION) {
        history.push("/dashboard");
      } else {
        sessionStorage.clear();
      }
    }
  }, [history]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
    
        const response = await axios.post('https://carwashapis.gosmart.ae/verify_Login_dashboard', {
        email: email,
        password: password,
      });
      if (response.data.message === "login successfully") {
        const loginTime = new Date().getTime();
        sessionStorage.setItem('loggedInEmail', email);
        sessionStorage.setItem('loginTime', loginTime);
        
        // Fetch the token
        const tokenResponse = await axios.post("https://carwashapis.gosmart.ae/user_login",{
          flag: true,
          login:email
        });
        setToken(tokenResponse.data.access_token); // Assuming the response contains the token in this structure
        console.log("Token Response:", tokenResponse.data.access_token);
        
        history.push("/dashboard");
      } else {
        setError("Login failed. Please check your email and password.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Login error:", error);
      setError("An error occurred. Please try again later.");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="wrapper">
        <div className="Left-Side">
          <div className="img-container">
            <img src={car} className="carwashlogo" alt="Car Wash Logo" />
          </div>
          <h5>Car Wash</h5>
          <p>
            Always remember that every client is satisfied with the amount of
            efforts and dedication to work. We are here to support you every
            step of the way, working together to excel and exceed expectations.
          </p>
        </div>

        <div className="right-Side">
          <div className="titleform">
            <h4>Welcome back!</h4>
          </div>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1label" id="labelfont">Email address</label>
              <input
                type="email"
                className="form-control"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1" id="labelfont">Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                onChange={handlePasswordChange}
                required
              />
            </div>
            <button type="submit" id="loginformbtn" className="btn" disabled={loading}>
              {loading ? 'Logging in...' : 'Login'}
            </button>
            {error && <div className="error-message">{error}</div>}
          </form>
        </div>
      </div>
    </div>
  );
}
