import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import ProfileHeader from "../pages/Components";
import PackagePricing from "./PackagePricing";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import edit from "/webapps/carwash_landing/src/assets/edit-text.png";
import save from "/webapps/carwash_landing/src/assets/pngwing.com (2).png";
import deletee from "/webapps/carwash_landing/src/assets/Frame 1770.png";
import { useToken } from "../components/TokenContext";

export default function PackageInfo() {
  const { token } = useToken();
  const { id } = useParams();
  const history = useHistory();
  const [values, setValues] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const textAreaRef = useRef(null);
  const [activeTag, setActiveTag] = useState("tag1");
  const [content, setContent] = useState("PackageFeatures");
  const [Features, setFeatures] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({});
  const [editFeatureIndex, setEditFeatureIndex] = useState(null);
  const [editFeatureText, setEditFeatureText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const changeContent = (id, tagname) => {
    switch (id) {
      case "Package-Features":
        setContent("PackageFeatures");
        break;
      case "Package-Pricing":
        setContent("PackagePricing");
        break;
      default:
        setContent("");
        break;
    }
    setActiveTag(tagname);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    if (content === "PackageFeatures") {
      axios
        .get(`https://carwashapis.gosmart.ae/details_of/package_featuers/package_id/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setFeatures(res.data.data);
          console.log(Features)
        })
        .catch((err) => console.error("Error fetching service provider data:", err));
    }
  }, [id, content]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [values.package_details, values.package_features]);

  useEffect(() => {
    axios.get(`https://carwashapis.gosmart.ae/get_by_id/carwash_package/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => setValues(res.data.data[0]))
      .catch((err) => console.log(res));
  }, [id]);

  const handleUpdate = (event) => {
    event.preventDefault();
    axios.post("https://carwashapis.gosmart.ae/Update_record", {
      object_id: id,
      table_name: "carwash.package",
      data: values
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        console.log(res);
        history.push('/packages');
      })
      .catch((err) => console.log(res));
  };

  const getUpdatedFields = (original, updated) => {
    const updatedFields = {};
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        updatedFields[key] = updated[key];
      }
    }
    return updatedFields;
  };

  const handleUpdate2 = (id, values) => {
    axios.post("https://carwashapis.gosmart.ae/Update_record", {
      object_id: id,
      table_name: "package.featuers",
      data: values,
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res);
        const updatedData = [...Features];
        updatedData[editIndex] = { ...updatedData[editIndex], ...values };
        setFeatures(updatedData);
        setEditIndex(null);
        setEditFeatureIndex(null);
      })
      .catch((err) => console.log(err));
  };

  const featuresArray = values?.package_features ? values.package_features.split('\n') : [];

  const handleCheckboxChange = (id) => {
    const selectedIndex = selectedItems.indexOf(id);
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    }
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditFeatureText(Features[index].featuers);
  };

  const handleSaveClick = (index) => {
    const updatedFeatures = [...Features];
    updatedFeatures[index].featuers = editFeatureText;

    handleUpdate2(updatedFeatures[index].id, { featuers: updatedFeatures[index].featuers });
    setEditIndex(null);
    setEditFeatureText('');
  };

  const handleRemoveClick = (index) => {
    setDeleteId(index);
    setIsOpen(true);
  };

  const handleDelete = (index) => {
    const updatedFeatures = [...Features];
    const featureId = updatedFeatures[index].id; // Get the ID of the feature to be deleted

    axios
      .delete(`https://carwashapis.gosmart.ae/delete_record/package.featuers/${featureId}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        // Remove the feature from the state after successful deletion
        updatedFeatures.splice(index, 1);
        setFeatures(updatedFeatures);
        setIsOpen(false);
      })
      .catch((error) => {
        console.error("There was an error deleting the feature!", error);
      });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <ProfileHeader />
      <div className="title">
        <h1>
          <Link to="/packages"><img src={backbtn} style={{ marginRight: "1rem" }} /></Link>
          Package Info
        </h1>
      </div>

      <div className="grey-container">
        <div className="row">
          <div className="col-4">
            <span className="holder">Name :</span>
            <span className="values"> {values.package_name}</span>
          </div>
        </div>
      </div>

      <div className="menu-list pt-5 w-100">
        <ul>
          <li
            onClick={() => changeContent("Package-Features", "tag1")}
            className={`List-item3 ${activeTag === "tag1" ? "active" : ""}`}
          >
            <button className="all">Package Features</button>
          </li>
          <li
            onClick={() => changeContent("Package-Pricing", "tag2")}
            className={`List-item3 ${activeTag === "tag2" ? "active" : ""}`}
          >
            <a>Package Pricing</a>
          </li>
        </ul>
      </div>

      <div
        id="content1"
        style={{ display: content === "PackageFeatures" ? "block" : "none" }}
      >
        <div className="mt-5 pb-5">
          <div className="d-flex justify-content-end">
            <span className="adduserbtn">
              <Link to={`/addfeature/${id}`}>+ Add Feature </Link>
            </span>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>Features</th>
              </tr>
            </thead>
            <tbody>
              {Features.map((d, i) => (
                <tr key={i}>
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {editIndex === i ? (
                        <textarea
                          className="form-control"
                          value={editFeatureText}
                          onChange={(e) => setEditFeatureText(e.target.value)}
                          rows={1}
                          style={{
                            width: "100%",
                            height: "50%",
                            borderRadius: "5px",
                            padding: "5px",
                            border: "1px solid #DCDCDC"
                          }}
                        />
                      ) : (
                        <span>{d.featuers}</span>
                      )}
                      <div style={{ marginLeft: "auto", display: "flex" }}>
                        {editIndex === i ? (
                          <button
                            className="btn editbtn btn-sm"
                            onClick={() => handleSaveClick(i)}
                          >
                            <img src={save} className="editbackground" />
                          </button>
                        ) : (
                          <button
                            className="btn editbtn btn-sm"
                            onClick={() => handleEditClick(i)}
                          >
                            <img src={edit} className="editbackground" />
                          </button>
                        )}
                        <button
                          className="btn deletebtn btn-sm"
                          onClick={() => handleRemoveClick(i)}
                        >
                          <img src={deletee} className="editbackground" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <PackagePricing handleChange={handleChange} content={content} />


      {/* Modal for confirmation */}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal" className="Modal shadow"
      >
        <h2>Confirm Deletion</h2>
        <p>Are you sure you want to delete this feature?</p>
        <div className="btns">
        <button className="Confirm" onClick={() => handleDelete(deleteId)}>Yes</button>
        <button className="cancel" onClick={closeModal}>No</button>
        </div>
      </Modal>
    </div>
  );
}
