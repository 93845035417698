import { NavLink } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent
} from 'react-pro-sidebar';
import {
  FaUser,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaTachometerAlt,
  FaGem,
  FaList,
  FaRegLaughWink,
  FaHeart
} from 'react-icons/fa';
import sidebarBg from '../assets/bg1.jpg';
import "../styles/Side.css"
import { Link, useHistory, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Sidebar = ({
  image,
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange
}) => {
  const history = useHistory(); // Initialize useHistory

  const handlego = () => {
    history.push('/'); // Replace with your actual route


  };
  return (
    <ProSidebar
      image={image ? sidebarBg : false}
      collapsed={collapsed}
      toggled={toggled}
      onToggle={handleToggleSidebar}
      breakPoint="md"
    >
      {/* Header */}
      <SidebarHeader>
      <Menu iconShape="circle">
    {collapsed ? (
      <MenuItem
        icon={<FaAngleDoubleRight />}
        onClick={handleCollapsedChange}
      ></MenuItem>
    ) : (
      <MenuItem
        suffix={<FaAngleDoubleLeft />}
        onClick={handleCollapsedChange}
      >
        
          <div
            style={{
              padding: '9px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 15,
              letterSpacing: '1px'
            }}
            
          >
            <button onClick={handlego} class="carwashbtn">
              CarWash
              </button>
          </div>
      </MenuItem>
    )}
  </Menu>
      </SidebarHeader>
      {/* Content */}
      <SidebarContent>
        <Menu iconShape="circle">
          <SubMenu
            // suffix={<span className="badge yellow">3</span>}
            title={'Customers'}
            icon={<FaRegLaughWink />}
          >
            <MenuItem>
              All Customers <NavLink to='/dashboard' />
            </MenuItem>
            <MenuItem>
              Archive <NavLink to="/userarchive" />
            </MenuItem>
          </SubMenu>
  
          <SubMenu
            title={'Clients'}
            icon={<FaRegLaughWink />}
          >
            <MenuItem>
              All Clients <NavLink to='/clients' />
            </MenuItem>
            <MenuItem>
              Archive <NavLink to="/clientsarchive" />
            </MenuItem>
          </SubMenu>
          <MenuItem icon={<FaGem />}>
            Subscription <NavLink to="/subscriptions" />
          </MenuItem>
          <MenuItem icon={<FaGem />}>
            Payments <NavLink to="/payment" />
          </MenuItem>
          <SubMenu
            // suffix={<span className="badge yellow">3</span>}
            title={'Operations'}
            icon={<FaRegLaughWink />}
          >
            <MenuItem>
              All Operations <NavLink to='/operations' />
            </MenuItem>
            <MenuItem>
              Future <NavLink to="/futureOperations" />
            </MenuItem>
            <MenuItem>
              History <NavLink to="/oldOperations" />
            </MenuItem>
            <MenuItem>
              Archive <NavLink to="/operationArchive" />
            </MenuItem>
          </SubMenu>
        

          <MenuItem icon={<FaGem />}>
            Centers <NavLink to="/providers" />
          </MenuItem>
          
          <SubMenu title={'Service'} icon={<FaList />}>
            <MenuItem>
              All Services <NavLink to="/allservices" />
            </MenuItem>
            <MenuItem>
              Archive <NavLink to="/serviceArchive" />
            </MenuItem>
            <MenuItem>
              Options <NavLink to="/options" />
            </MenuItem>
          </SubMenu>
          <MenuItem icon={<FaGem />}>
            Packages <NavLink to="/packages" />
          </MenuItem>
          <MenuItem icon={<FaGem />}>
            Emails <NavLink to="/emails" />
          </MenuItem>
        
         
        </Menu>
      </SidebarContent>
      {/* Footer */}
      {/* <SidebarFooter style={{ textAlign: 'center' }}>
        <div className="sidebar-btn-wrapper" style={{ padding: '16px' }}>
          <NavLink className="sidebar-btn" to="/profile" style={{ cursor: 'pointer' }}>
            <FaUser />
            <span>My Account</span>
          </NavLink>
        </div>
      </SidebarFooter> */}
    </ProSidebar>
  );
};

export default Sidebar;
