import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import "../styles/updateform.css";
import ProfileHeader from "../pages/Components";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import { useToken } from "../components/TokenContext";

export default function OperationUnArchive() {
  const { token } = useToken();
  const history = useHistory();
  const { id } = useParams();
  const [isArchive, setIsArchive] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    axios.get(`https://carwashapis.gosmart.ae/get_by_id/carwash_operation/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },})
      .then((res) => {
        const archiveStatus = res.data.data[0].is_archive;
        setIsArchive(archiveStatus === true || archiveStatus === "true");
      })
      .catch((err) => console.log(err));
  }, [id]);
  
  const handleUpdate = (event) => {
    event.preventDefault();
    axios.post(
      "https://carwashapis.gosmart.ae/Update_record",
      {
        object_id: Number(id),  // Ensure object_id is a number
        table_name: "carwash.operation",
        data: { is_archive: isArchive }  // Ensure is_archive is correctly included within data object
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(res => {
      console.log(res);
      history.push('/operations');
    })
    .catch((err) => {
      console.error(err);
      setError('An error occurred while updating the record.');
    });
  };
  const handleInputChange = (e) => {
    setIsArchive(e.target.value === "true");
  };

  return (
    <div className="d-flex w-100 vh-100">
      <div className="w-100 border pt-3 pb-5 rounded">
        <ProfileHeader />
        <div className="title">
          <h1>
            <Link to="/operations"><img src={backbtn} style={{ marginRight: "1rem" }} /></Link>
            Update Operation Archive
          </h1>
        </div>

        <form onSubmit={handleUpdate}>
          <div className="row">
            <div className="mb-2 col-6">
              <label htmlFor="is_archive">is Archive:</label>
              <select
                name="is_archive"
                id="updateforminput"
                className="form-select"
                value={isArchive.toString()}
                onChange={handleInputChange}
              >
                <option value="true">true</option>
                <option value="false">false</option>
              </select>
            </div>
          </div>
          {error && <p className="text-danger">{error}</p>}
          <div className="pull-right">
            <button className="btn updatepagebtn mt-4">Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}
