import axios from "axios";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import ProfileHeader from "../pages/Components";
import { useToken } from "../components/TokenContext";

export default function Create() {
  const history = useHistory();
  const [values, setValues] = useState({
    name: "",
    id: "",
    phone: "",
    latitude: "",
    longitude: "",
    password: "",
    confirm_password: ""
  });
  const { token } = useToken();
  const handleformsubmit = (event) => {
    event.preventDefault();
    axios.post("https://carwashapis.gosmart.ae/create_record", {
      table_name: "carwash.user",
      data: values
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        console.log(res);
        history.push('/');


      })
      .catch((err) => console.log(res));
  };
  return (
    <div className="d-flex w-100 vh-100 ">
      <div className="w-100 border pt-3 pb-5 rounded">
        <ProfileHeader />
        <div className="title">
          <h1>        <Link to="/dashboard"><img src={backbtn} style={{ marginRight: "1rem" }} /></Link>
            Add Customers</h1>

        </div>        <form onSubmit={handleformsubmit}>
          <div className="row">
            <div className="mb-3 col-6">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                name="name"
                id="updateforminput"
                className="form-control"
                placeholder="Enter Name"
                onChange={(e) => setValues({ ...values, name: e.target.value })}
              />
            </div>
            <div className="mb-3 col-6">
              <label htmlFor="name">Image :</label>
              <input
                type="text"
                name="name"
                id="updateforminput"
                className="form-control"
                placeholder="Enter Img URL"
                onChange={(e) => setValues({ ...values, id: e.target.value })}
              />
            </div>

            <div className="mb-3 col-6">
              <label htmlFor="name">Phone:</label>
              <input
                type="text"
                name="name"
                id="updateforminput"
                className="form-control"
                placeholder="Enter Phone"
                onChange={(e) => setValues({ ...values, phone: e.target.value })}
              />
            </div>

            <div className="mb-3 col-6">
              <label htmlFor="name">Latitude:</label>
              <input
                type="text"
                name="name"
                id="updateforminput"
                className="form-control"
                placeholder="Enter Latitude"
                onChange={(e) =>
                  setValues({ ...values, latitude: e.target.value })
                }
              />
            </div>
            <div className="mb-3 col-6">
              <label htmlFor="name">Longitude:</label>
              <input
                type="text"
                name="name"
                id="updateforminput"
                className="form-control"
                placeholder="Enter Longitude"
                onChange={(e) =>
                  setValues({ ...values, longitude: e.target.value })
                }
              />
            </div>
            <div className="mb-3 col-6">
              <label htmlFor="name">Password:</label>
              <input
                type="text"
                name="name"
                id="updateforminput"
                className="form-control"
                placeholder="Enter Password"
                onChange={(e) =>
                  setValues({ ...values, password: e.target.value })
                }
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="name">Confirm Password:</label>
              <input
                type="text"
                name="name"
                id="updateforminput"
                className="form-control"
                placeholder="Enter Password"
                onChange={(e) =>
                  setValues({ ...values, confirm_password: e.target.value })
                }
              />
            </div>
          </div>
          <div className="pull-right">
            <button className="btn updatepagebtn mt-4">Add</button></div>
          {/* <Link to="/" className="btn btn-primary ms-3">
            Back
          </Link> */}
        </form>
      </div>
    </div>
  );
}
