import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useToken } from "../components/TokenContext";

const Content4 = ({ content }) => {
  const { id } = useParams();
  const [Rating, setRating] = useState([]);
  const [editIndex4, setEditIndex] = useState(null);
  const [editData4, setEditData] = useState({});
  const { token } = useToken();

     useEffect(() => {axios.get(`https://carwashapis.gosmart.ae/details_of/service_rating/service_id/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
       .then((res) => {
         setRating(res.data.data);
       })
       .catch((err) => console.error("Error fetching data:", err));
   }, [id , content ]);

  const handleEditClick4 = (index) => {
    setEditIndex(index);
    setEditData(Rating[index]);
  };

  const handleSaveClick4 = (index) => {
    const originalData = Rating[index];
    const updatedFields = getUpdatedFields(originalData, editData4);
    if (Object.keys(updatedFields).length > 0) {
      handleUpdate4(editData4.id, updatedFields);
    } else {
      setEditIndex(null);
    }
  };

  const handleChange4 = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getUpdatedFields = (original, updated) => {
    const updatedFields = {};
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        updatedFields[key] = updated[key];
      }
    }
    return updatedFields;
  };

  const handleUpdate4 = (id, values) => {
    axios.post("https://carwashapis.gosmart.ae/Update_record", {
      object_id: id,
      table_name: "service.rating",
      data: values,
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res);
        const updatedData = [...Rating];
        updatedData[editIndex4] = { ...updatedData[editIndex4], ...values };
        setRating(updatedData);
        setEditIndex(null);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div id="content4" style={{ display: content === "Rating" ? "block" : "none" }}>
    <div className=" mt-5">
      <table className="table ">
        <thead>
          <tr>
            <th>ID</th>
            <th>Center Name</th>
            <th>Rate</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {Rating.map((d, i) => (
            <tr key={i}>
              <td>{d.id}</td>
       
              <td>
              {editIndex4 === i ? (
                  <input
                    type="text"
                    name="provider_name"
                    className="updateinputs"
                    value={editData4.provider_name}
                    onChange={handleChange4}
                  />
                ) : (
                  d.provider_name
                )}
              </td>
              <td>
              {editIndex4 === i ? (
                  <input
                    type="text"
                    name="last_rating"
                    className="updateinputs"
                    value={editData4.last_rating}
                    onChange={handleChange4}
                  />
                ) : (
                  d.last_rating
                )}
              </td>
              <td>
              {editIndex4 === i ? (
                  <input
                    type="text"
                    name="comment"
                    className="updateinputs"
                    value={editData4.comment}
                    onChange={handleChange4}
                  />
                ) : (
                  d.comment
                )}
              </td>
         
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default Content4;
