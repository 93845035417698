import React from "react";
import "./WebStyles/Applications.css";
import applicationsimg from "./WebAssets/appcardimg.png";
import cardimg2 from "./WebAssets/cardimg2.png";
import cardimg3 from "./WebAssets/cardimg3.png";
import cardimg4 from "./WebAssets/cardimg4.png";
export default function Applications() {
  return (
    <div>
      <div className="Applications-sec" id="Applications">
        <div className="Applications-sec-text">
          <h6>Application Features</h6>
        </div>

        <div className="container cards-flex">
          <div className="app-card">
            <div className="app-card-left">
              <h6>Reservations management</h6>
              <p>
                {/* Providing a smooth and comfortable experience for customers,
                improving time and resource management for the organization,
                enhancing work efficiency, and maximizing economic and
                operational potential. Reservation management plays a crucial
                role in the success of businesses and achieving customer
                satisfaction. */}

Providing a smooth customer experience and improving organizational efficiency are crucial.   Effective reservations ensure satisfaction.           </p>
            </div>
            <div className="app-card-right1">
              <img src={applicationsimg} />
            </div>
          </div>
          <div className="app-card">
            <div className="app-card-left">
              <h6>Response speed</h6>
              <p>

              Reservations involve the booking process, confirmation speed, and system responsiveness. Efficiency in these aspects is crucial.  </p>
            </div>
            <div className="app-card-right1">
              <img src={cardimg2} />
            </div>
          </div>
          <div className="app-card">
            <div className="app-card-left">
              <h6>Add services</h6>
              <p>
              It allows the fresher to stay competitive in a dynamic market and adapt to customers and their changing preferences over time
              </p>
            </div>
            <div className="app-card-right1">
              <img src={cardimg3} />
            </div>
          </div>
          <div className="app-card">
            <div className="app-card-left">
              <h6>Account management</h6>
              <p>
              Foster long-term customer relationships, increase customer satisfaction, and ultimately contribute to the success and growth of the business
              </p>
            </div>
            <div className="app-card-right1">
              <img src={cardimg4} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
