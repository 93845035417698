import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link ,useHistory, useParams } from "react-router-dom";
import "../styles/updateform.css"
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import ProfileHeader from "../pages/Components";
import { useToken } from "../components/TokenContext";

export default function Update() {
    // const [data , setData] = useState([]);
    const history = useHistory();
     const {id} = useParams ();
     const [values, setValues] = useState({
     
    });
    const [error, setError] = useState('');
    const { token } = useToken();

     useEffect(() => {
      axios.get(`https://carwashapis.gosmart.ae/get_by_id/carwash_user/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

    .then((res) => setValues(res.data.data[0]))
  .catch((err) => console.log(res));
     }, []);
 
     const handleUpdate = (event) => {
      event.preventDefault();
      if (values.password !== values.confirm_password) {
        setError('Passwords do not match');
        return;
      }

      axios.post("https://carwashapis.gosmart.ae/Update_record", {
      object_id:id,
      table_name:"carwash.user",
      data:values
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    .then(res => {console.log(res);
      history.push('/dashboard');
   })
    .catch((err) => console.log(res));
    };


     
  return (
    <div className="d-flex w-100 vh-100 ">
      <div className="w-100 border pt-3 pb-5 rounded">
        <ProfileHeader />
        <div className="title">
          <h1>        <Link to="/dashboard"><img src={backbtn} style={{ marginRight: "1rem" }} /></Link>
             Update User </h1>

        </div>          <form onSubmit={handleUpdate}>
          <div className="row">
          <div className="mb-2 col-6">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              id="updateforminput"
              className="form-control"
              placeholder="Enter Name"
              value={values.name || ""}
              onChange={(e) =>
                setValues({ ...values, name: e.target.value })
               }            />
          </div>
          <div className="mb-2 col-6">
            <label htmlFor="name">ID :</label>
            <input
              type="text"
              name="name"
              id="updateforminput"
              className="form-control"
              placeholder="Enter Img URL"
              value={values.id}
              onChange={(e) =>
                setValues({ ...values, id: e.target.value })
               }
            />
          </div>

          <div className="mb-2 col-6">
            <label htmlFor="name">Phone:</label>
            <input
              type="text"
              name="name"
              id="updateforminput"
              className="form-control"
              placeholder="Enter Phone"
              value={values.phone}
              onChange={(e) =>
                setValues({ ...values, phone: e.target.value })
               }
            />
          </div>

          <div className="mb-2 col-6">
            <label htmlFor="name">Latitude:</label>
            <input
              type="text"
              name="name"
              id="updateforminput"
              className="form-control"
              placeholder="Enter Latitude"
              value={values.latitude}
              onChange={(e) =>
                setValues({ ...values, latitude: e.target.value })
               }
            />
          </div>
          <div className="mb-2 col-6">
            <label htmlFor="name">Longitude:</label>
            <input
              type="text"
              name="name"
              id="updateforminput"
              className="form-control"
              placeholder="Enter Longitude"
              value={values.longitude}
              onChange={(e) =>
                setValues({ ...values, longitude: e.target.value })
               }
            />
          </div>
          {/* <div className="mb-2 col-6">
            <label htmlFor="name">New Password:</label>
            <input
              type="text"
              name="name"
              id="updateforminput"
              className="form-control"
              placeholder="Enter Password"
              onChange={(e) =>
                setValues({ ...values, password: e.target.value })
               }
            />
          </div> */}
          {/* <div className="mb-2 col-12">
            <label htmlFor="name">New Confirm Password:</label>
            <input
              type="text"
              id="updateforminput"
              name="name"
              className="form-control"
              placeholder="Enter Password"
              onChange={(e) =>
                setValues({ ...values, confirm_password: e.target.value })
               }
            />{error && <div className="text-danger">{error}</div>}
          </div> */}
          </div>
          <div className="pull-right">
          <button className="btn updatepagebtn mt-4">Update</button></div>
          {/* <Link to="/" className="btn btn-primary ms-3">
            Back
          </Link> */}
        </form>
      </div>
    </div>
  );
}
