import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import { useToken } from "../components/TokenContext";

export default function Addprice() {
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { token } = useToken();


  useEffect(() => {
    axios.get(`https://carwashapis.gosmart.ae/get_by_id/carwash_package/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },} )
      .then((res) => {
        const packageName = res.data.data[0].package_name;
        setData(packageName);
        setValues(prevValues => ({
          ...prevValues,
          package_name: packageName
        }));
      })
      .catch((err) => console.log("Error fetching data:", err));
  }, [id]);


  const [values, setValues] = useState({
    package_name:"",
    period: "",
    count: "",
    price: ""
  });

  const handleformsubmit = (event) => {
    event.preventDefault();
    console.log("Form Values:", values); // Debugging statement
  
    axios.post(
      "https://carwashapis.gosmart.ae/add_price_package",
      {
        ...values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(res => {
      console.log("Response:", res); // Debugging statement
      history.push(`/packageinfo/${id}`);
    })
    .catch(err => console.error("Error:", err)); // Improved error handling
  };
  

  return (
    <div className="d-flex w-100 vh-100">
      <div className="w-100 border pt-3 pb-5 rounded">
      <div className="title">
          <h1>
            <Link to={`/packageinfo/${id}`}><img src={backbtn} style={{ marginRight: "1rem" }} /></Link>
            Add Period and Price
          </h1>
        </div>        <form onSubmit={handleformsubmit}>
          <div className="row">
          {/* <div className="mb-3 col-6">
              <label htmlFor="period">Period:</label>
              <select
                name="period"
                id="updateforminput"
                className="form-control"
                onChange={(e) => setValues({ ...values, period: e.target.value })}
              >
                <option value="">Select period</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </select>
            </div> */}
            <div className="mb-3 col-6">
              <label htmlFor="period">Period[Months]:</label>
              <input
                type="text"
                name="count"
                id="updateforminput"
                className="form-control"
                placeholder="Enter count"
                onChange={(e) => setValues({ ...values, count: e.target.value })}
              />
            </div>
            <div className="mb-3 col-6">
              <label htmlFor="price">Price:</label>
              <input
                type="text"
                name="price"
                id="updateforminput"
                className="form-control"
                placeholder="Enter price"
                onChange={(e) => setValues({ ...values, price: e.target.value })}
              />
            </div>
          </div>
          <div className="pull-right">
            <button className="btn updatepagebtn mt-4" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Add'}
            </button>
          </div>
          {/* <Link to="/" className="btn btn-primary ms-3">
            Back
          </Link> */}
        </form>
      </div>

    </div>
  );
}
