import React, { useRef } from 'react'
import ProfileHeader from '../pages/Components'
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link, useParams } from 'react-router-dom';
import '../styles/Service.css'
import fuel from "/webapps/carwash_landing/src/assets/fuel 1.png"
import plus from "/webapps/carwash_landing/src/assets/Component 20 (1).png"
import { useToken } from "../components/TokenContext";

export default function AllServices() {
    const [data, setData] = useState([]);
    const {id} = useParams ();
    const descriptionRef = useRef(null);
    const { token } = useToken();

    useEffect(() => {
        if (descriptionRef.current) {
          const text = descriptionRef.current.innerText;
          if (text.length > 100) {
            descriptionRef.current.innerText = text.substring(0, 100) + '...';
          }
        }
      }, []);

   useEffect(() => {
      axios.get("https://carwashapis.gosmart.ae/get_all/carwash_service",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
 
   .then((res) => setData(res.data.data))
   .catch((err) => console.log(res));
    }, []);
  return (
    <div>  
    <ProfileHeader/>
    <h1 className="title">All Services</h1>
          <div className="d-flex justify-content-end">
        <span className="adduserbtn">
          <Link to={'/addservice'}>+ Add Service </Link>
        </span>
      </div>
        {/* <table class="table">
  <thead>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Name</th>
      <th scope="col" >Description</th>
      <th scope="col">Time</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>


{

data.map((d,i)=>(

<tr key={i}>

<td>{d.id}</td>
<td>{d.name}</td>
<td className='des'>{d.description.substring(0, 100)}</td>
<td>{d.time}</td>
<td>
<Link to={`/serviceinfo/${d.id}`}><button className="btn btn-sm btn-secondary mr-2">Read</button></Link>
<Link to={`/update/${d.id}`}><button className="btn btn-sm btn-primary mr-2">Edit</button></Link>
<button className="btn btn-sm btn-danger">Delete</button>
</td>

</tr>

))

}
  </tbody>
</table> */}

 {/* <div class="card" >
    <div className='wrapper-service'>
    <img src={fuel} class="card-icon-service" alt="..."/>
    </div>
  <div class="card-body">
    <h5 class="card-title">Change Oil </h5>
  </div>
</div>  */}

<div className="cards-layout">

{data.map(item => (
  <div className="cards-layout">
  <Link to={`/serviceinfo/${item.id}`}>
        <div key={item.id} className="card">
          <div className='wrapper-service'>
            <img src={item.image} className="card-icon-service"  alt="..." 
            />
          </div>
          <div className="card-body">
            <h5 className="card-title">{item.name}</h5>
          </div>
        </div>
        </Link>
        </div>
      ))}
    </div>
    </div>
  )
}
