import React, { useState, useEffect } from "react";
import ProfileHeader from '../pages/Components'
import { Link } from 'react-router-dom'
import deletee from "/webapps/carwash_landing/src/assets/Frame 1770.png";
import axios from "axios";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import edit from "/webapps/carwash_landing/src/assets/edit-text.png";
import save from "/webapps/carwash_landing/src/assets/pngwing.com (2).png";
import { useToken } from "../components/TokenContext";


export default function Options() {
    const { token } = useToken();
    const [data, setData] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [editData, setEditData] = useState({});
    const { id } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
        axios
          .get("https://carwashapis.gosmart.ae/get_all/option_provider", {
            headers: {
              Authorization: `Bearer ${token}`,
            },})
          .then((res) => setData(res.data.data))
          .catch((err) => console.log(res));
      }, []);
    
  const handleCheckboxChange = (id) => {
    const updatedData = data.map((item) =>
      item.id === id ? { ...item, is_archive: !item.is_archive } : item
    );
    setData(updatedData);
    const updatedItem = updatedData.find((item) => item.id === id);
    handleUpdate(id, { is_archive: updatedItem.is_archive });

  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditData(data[index]);
  };
  const handleSaveClick = (index) => {
    const originalData = data[index];
    const updatedFields = getUpdatedFields(originalData, editData);
    if (Object.keys(updatedFields).length > 0) {
      handleUpdate(editData.id, updatedFields);
    } else {
      setEditIndex(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getUpdatedFields = (original, updated) => {
    const updatedFields = {};
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        updatedFields[key] = updated[key];
      }
    }
    return updatedFields;
  };

  const openModal = (id) => {
    setDeleteId(id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleUpdate = (id, values) => {
    axios.post(
      "https://carwashapis.gosmart.ae/Update_record",
      {
        object_id: id,
        table_name: "option.provider",
        data: values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      console.log(res);
      const updatedData = [...data];
      updatedData[editIndex] = { ...updatedData[editIndex], ...values };
      setData(updatedData);
      setEditIndex(null);
    })
    .catch((err) => console.log(err));
  };
  

  const handleDelete = (id) => {
    axios
      .delete(`https://carwashapis.gosmart.ae/delete_record/option.provider/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(data.filter((user) => user.id !== id)); // Remove the deleted user from the data array
        setIsOpen(false); // Close the modal
      })
      .catch((err) => console.log(err));
      window.location.reload();

  };
  

    return (
        <div>
            <ProfileHeader />
            <h1 className="updateuser">Options</h1>

            <div className="d-flex justify-content-end">
                <span className="adduserbtn">
                    <Link to="/addoption">+ Add Option  </Link>
                </span>
            </div>
            <table className="table">
        <thead>
          <tr>
            <th scope="col">Option Name</th>
            <th scope="col">Service Name</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              <td>
                {editIndex === i ? (
                  <input
                    type="text"
                    name="option_name"
                    className="updateinputs"
                    value={editData.option_name}
                    onChange={handleChange}
                  />
                ) : (
                  d.option_name
                )}
              </td>
   
              <td>
                {d.service_name}
              </td>
        
              <td>
                <div class="button-container d-flex">
                {editIndex === i ? (
                  <button
                    className="btn btn-sm editbtn mr-2"
                    onClick={() => handleSaveClick(i)}
                  >
                  <img src={save} className="editbackground" />

                  </button>
                ) : (
                  <button
                    className="btn btn-sm editbtn mr-2"
                    onClick={() => handleEditClick(i)}
                  >
                      <img src={edit} className="editbackground" />
                  </button>
                )}

                  <button
                  className="btn btn-sm deletebtn"
                  onClick={() => openModal(d.id)}
                >
                  <img src={deletee} className="editbackground" />
                </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="Modal shadow"
      >
        <h2>Confirm Delete</h2>
        <p>Are you Sure you want to delete this record .</p>
        <div className="btns">
          <button
            className="Confirm"
            onClick={() => {
              handleDelete(deleteId);
              setIsOpen(false);
            }}
          >
            Confirm
          </button>
          <button class="cancel" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </Modal>

        </div>
    )
}
