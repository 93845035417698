import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import "../styles/updateform.css";
import ProfileHeader from "../pages/Components";
import backbtn from "/webapps/carwash_landing/src/assets/back-button.png";
import { useToken } from "../components/TokenContext";

export default function UpdatePackage() {
  const { token } = useToken();
  const history = useHistory();
  const { id } = useParams();
  const [values, setValues] = useState({
    package_active: "",
    is_archive: ""
  });
  const [initialPackageName, setInitialPackageName] = useState('');
  const [packageName, setPackageName] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [message, setMessage] = useState("");

  useEffect(() => {
    axios
      .get(`https://carwashapis.gosmart.ae/get_by_id/carwash_package/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.data.data[0];
        setInitialPackageName(data.package_name);
        setPackageName(data.package_name);
        const initialData = {
          package_active: data.package_active,
          is_archive: data.is_archive
        };
        setValues(initialData);
        setInitialValues(initialData);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handleUpdate = (event) => {
    event.preventDefault();
    
    if (
      packageName === initialPackageName &&
      values.package_active === initialValues.package_active &&
      values.is_archive === initialValues.is_archive
    ) {
      setMessage("No changes made to update.");
      return;
    }

    axios.post("https://carwashapis.gosmart.ae/update_package", {
        package_name: initialPackageName,
        new_name: packageName,
        package_active: values.package_active,
        is_archive: values.is_archive
      },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMessage("Package updated successfully.");
        console.log(res);
        history.push("/packages");
      })
      .catch((err) => {
        setMessage("Failed to update package. Please try again.");
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  return (
    <div className="d-flex w-100 vh-100">
      <div className="w-100 border pt-3 pb-5 rounded">
        <ProfileHeader />
        <div className="title">
          <h1>
            <Link to="/packages">
              <img src={backbtn} style={{ marginRight: "1rem" }} />
            </Link>
            Edit Package
          </h1>
        </div>
        {message && <div className="alert alert-info">{message}</div>}
        <form onSubmit={handleUpdate}>
          <div className="row">
            <div className="mb-2 col-6">
              <label htmlFor="package_name">Package Name:</label>
              <input
                type="text"
                name="package_name"
                id="updateforminput"
                className="form-control"
                placeholder="Package Name"
                value={packageName}
                onChange={(e) => setPackageName(e.target.value)}
              />
            </div>
            <div className="mb-2 col-6">
              <label htmlFor="package_active">Package Active:</label>
              <select
                name="package_active"
                id="updateforminput"
                className="form-select"
                value={values.package_active}
                onChange={handleChange}
              >
                <option value="">Select Status</option>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
            <div className="mb-2 col-6">
              <label htmlFor="is_archive">Is Archive:</label>
              <select
                name="is_archive"
                className="form-select"
                value={values.is_archive}
                onChange={handleChange}
              >
                <option value="">Select Status</option>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
          </div>
          <div className="pull-right">
            <button className="btn updatepagebtn mt-4">Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}
