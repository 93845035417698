import ProfileHeader from "../pages/Components";
import {
  FaUser,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaTachometerAlt,
  FaGem,
  FaList,
  FaRegLaughWink,
  FaHeart,
} from "react-icons/fa";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import "../Mails/emailform.css";
import { useToken } from "../components/TokenContext";

export default function SendEmail() {
  const { token } = useToken();
  const history = useHistory();
  const { id } = useParams();
  const [values, setValues] = useState({
    to_email: "",
  });
  const handlesubmit = (event) => {
    event.preventDefault();
    axios
      .post(
        "https://carwashapis.gosmart.ae/send-email/",
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        history.push("/");
      })
      .catch((err) => console.log(err));
  };
  

  return (
    <>
      <ProfileHeader />
      <h1 className="title">Emails</h1>

      <div className="pt-5">
        {/* <form action="" id="contact-form" onSubmit={handlesubmit}>
  <div class="input mb">
    <div class="hidden"><label for="name" class="required">From</label></div>
    <div class="icon-container"><input type="text" value={"mal3bk.23@gmail.com"} name="name" id="name" placeholder="Name" class="icon-content" /><FaUser className="icon" /></div>
  </div>

  <div class="input mb">
    
    <div class="hidden"><label for="email" class="required">To</label></div>
    <div class="icon-container"><input value={values.to_email}  onChange={(e) =>setValues({ ...values, to_email: e.target.value })} type="email" name="email" id="email" placeholder="To" class="icon-content" required/><span  class="fas fa-envelope icon"></span></div>
  </div>

  <div class="input mb">
    <div class="hidden"><label for="subject" class="required">Subject</label></div>
    <div class="icon-container"><input type="text" name="subject" id="subject" placeholder="Subject" class="icon-content" /><span class="fas fa-pencil-alt icon"></span></div>
  </div>

  <div class="input mb">
    <div class="hidden"><label for="message" class="required">Body</label></div>
    <div class="icon-container"><textarea name="message" id="message" cols="10" rows="5" placeholder="Message..." class="icon-content" ></textarea><span class="fas fa-quote-left icon"></span></div>
  </div>

  <div class="input mb">
    <button type="submit" class="send text-center">Send Message</button>
  </div>
</form> */}

        <form onSubmit={handlesubmit} >
          <div className="row">
          <div class="form-group col-6">
            <label for="exampleInputEmail1">From</label>
            <input
              value={"mal3bk.23@gmail.com"}
              type="email"
              class="form-control"
              id="updateforminput"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              disabled={true}
            />
          </div>
          <div class="form-group col-6">
            <label for="exampleInputPassword1">To</label>
            <input
              value={values.to_email}
              onChange={(e) =>
                setValues({ ...values, to_email: e.target.value })
              }
              type="text"
              class="form-control"
              id="updateforminput"
              placeholder="To"
            />
          </div>
          <div class="form-group col-6">
            <label for="exampleInputEmail1">Subject</label>
            <input
              type="email"
              class="form-control"
              id="updateforminput"
              aria-describedby="emailHelp"
              placeholder="Enter Subject"
            />
          </div>
          <div class="form-group col-12">
            <label for="exampleInputEmail1">Body</label>
            <textarea
              style={{ height: "200px" }}
              type="email"
              class="form-control"
              id="updateforminput10"
              aria-describedby="emailHelp"
              placeholder="Enter Message"
            />
          </div>
          </div>
          <div className="pull-right">
          <button className="btn updatepagebtn mt-4">Submit</button></div>
        </form>
      </div>
    </>
  );
}
