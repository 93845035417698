import React from 'react'
import Navbar from './Navbar'
import './WebStyles/Home.css'
import car from './WebAssets/car.png'
import Swipersection from '../Home/Swipersection'
import mobimg from './WebAssets/Group 1773.png'
import Businessplan from './businessplan'
import Applications from './Applications'
import Footer from './Footer'



export default function Main() {
  return (
    <>
    <Navbar />
    <div className="First-Sec container">
      <div className="First-Sec-text">
        <h6>
          Welcome!  <br/>We are here to assist <br/>  you in managing your project
          effectively.
        </h6>

        <p>
          We offer comprehensive support to help you every step of the way.
          From streamlining processes to optimizing resources, our team is
          dedicated to ensuring your project runs smoothly and successfully.
          Whether it's managing schedules, coordinating staff, or maximizing
          customer satisfaction, we're here to provide the guidance and
          assistance you need. Let us help you overcome the complexities of
          project management and achieve your goals with confidence.”
        </p>
      </div>

      <div className="First-Sec-img">

          <img src={car} alt="" />

      </div>
    </div>

    <Swipersection/>
    <div className="Third-Sec container">
      <div className="Third-Sec-text">
        <h6>
          Service Addition System
        </h6>
        <p>
        A flexible and effective way to manage the range of services provided to clients. This system aims to facilitate the process of adding new services and modifying existing services in an easy and direct way, allowing the organization to meet customer needs quickly and effectively.
          The system for adding and modifying a service usually includes
        </p>
      </div>

      <div className="Third-Sec-img">

          <img src={mobimg} alt="" />

      </div>
    </div>

     <Businessplan/>
    <Applications/> 

    <Footer/> 
  </>
  )
}
