import React, { useState, useEffect } from "react";
import ProfileHeader from "../pages/Components";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import save from "/webapps/carwash_landing/src/assets/pngwing.com (2).png";
import view from "/webapps/carwash_landing/src/assets/view-icon.png";
import { useToken } from "../components/TokenContext";


export default function Subscriptions() {
  const [data, setData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({});
  const { token } = useToken();


  useEffect(() => {
    axios
      .get("https://carwashapis.gosmart.ae/get_all/carwash_subscription",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setData(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  const handleCheckboxChange = (id) => {
    const updatedData = data.map((item) =>
      item.id === id ? { ...item, is_archive: !item.is_archive } : item
    );
    setData(updatedData);
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditData(data[index]);
  };

  const handleSaveClick = (index) => {
    const originalData = data[index];
    const updatedFields = getUpdatedFields(originalData, editData);
    if (Object.keys(updatedFields).length > 0) {
      handleUpdate(editData.id, updatedFields);
    } else {
      setEditIndex(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getUpdatedFields = (original, updated) => {
    const updatedFields = {};
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        updatedFields[key] = updated[key];
      }
    }
    return updatedFields;
  };

  const handleUpdate = (id, values) => {
    axios.post("https://carwashapis.gosmart.ae/Update_record", {
      object_id: id,
      table_name: "carwash.subscription",
      data: values,
    },{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res);
      const updatedData = [...data];
      updatedData[editIndex] = { ...updatedData[editIndex], ...values };
      setData(updatedData);
      setEditIndex(null);
    })
    .catch((err) => console.log(err));
  };

  return (
    <div>
      <ProfileHeader />
      <h1 className="title">Subscriptions</h1>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Client Name</th>
            <th scope="col">Email</th>
            <th scope="col">Package Name</th>
            <th scope="col">Current Status</th>
            <th scope="col">Center Name </th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              <td>{d.id}</td>
              <td>
                {editIndex === i ? (
                  <input
                    type="text"
                    name="client_name"
                    className="updateinputs"
                    value={editData.client_name}
                    onChange={handleChange}
                  />
                ) : (
                  d.client_name
                )}
              </td>
              <td>
                {editIndex === i ? (
                  <input
                    type="text"
                    name="email"
                    className="updateinputs"
                    value={editData.email}
                    onChange={handleChange}
                  />
                ) : (
                  d.email
                )}
              </td>
     
              <td>
                {editIndex === i ? (
                  <input
                    type="text"
                    name="package_name"
                    className="updateinputs"
                    value={editData.package_name}
                    onChange={handleChange}
                  />
                ) : (
                  d.package_name
                )}
              </td>
       
              <td>
                {editIndex === i ? (
                  <input
                    type="text"
                    name="current_status"
                    className="updateinputs"
                    value={editData.current_status}
                    onChange={handleChange}
                  />
                ) : (
                  d.current_status
                )}
              </td>
              <td>
                {editIndex === i ? (
                  <input
                    type="text"
                    name="provider_name"
                    className="updateinputs"
                    value={editData.provider_name}
                    onChange={handleChange}
                  />
                ) : (
                  d.provider_name
                )}
              </td>              <td>
                <div className="d-flex">

              <Link to={`/subscriberead/${d.id}`}>
              <button className="btn btn-sm readbtn ">
                  <img src={view} className="viewicon" />
                  </button>
                  </Link>

                {editIndex === i ? (
                  <button
                    className="btn btn-sm editbtn "
                    onClick={() => handleSaveClick(i)}
                  >
                  <img src={save} className="editbackground" />

                  </button>
                ) : (
                  <button
                    className="btn btn-sm editbtn"
                    onClick={() => handleEditClick(i)}
                  >
                    Edit
                  </button>
                )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
